import { Action, History, Location } from "history";
import queryString, {
  ParsedQuery,
  ParseOptions,
  StringifyOptions,
} from "query-string";
import { match } from "react-router-dom";

import scrollManager, { ScrollManager } from "./scrollManager";

export let navigator: Navigator;

export interface IRouter<
  MatchParams extends { [K in keyof MatchParams]?: string | undefined } = {}
> {
  history: History;
  location: Location;
  match: match<MatchParams>;
}

export interface IExtendedRoute extends Location {
  origin: string;
  href: string;
}

export interface IQueryParams {
  [param: string]: string | string[] | number | number[] | undefined;
}

interface IGoToOptions {
  replace?: boolean;
  hash?: string;
}

export interface INavigatorRoute {
  pathname: string;
  search?: string;
  hash?: string;
}

export type RouteChangeListener = (
  route: Location,
  leavingRoute: IExtendedRoute
) => void;

export class Navigator {
  public static defaultArrayFormat: ParseOptions["arrayFormat"] = "comma";

  public static defaultParseOptions: ParseOptions = {
    arrayFormat: Navigator.defaultArrayFormat,
    parseNumbers: true,
    parseBooleans: true,
  };

  public static defaultStringifyOptions: StringifyOptions = {
    arrayFormat: Navigator.defaultArrayFormat,
  };

  private router: IRouter;
  private routeChangeListeners: RouteChangeListener[];
  private currentRoute: IExtendedRoute;
  private scrollManager?: ScrollManager;

  constructor(router: IRouter) {
    this.router = router;
    this.routeChangeListeners = [];
    this.scrollManager = scrollManager;

    this.addListeners();
    this.currentRoute = this.prepareRoute(router.location);
  }

  public listen(listener: RouteChangeListener): void {
    if (!listener) {
      return;
    }

    this.routeChangeListeners.push(listener);
  }

  public stopListening(externalListener: RouteChangeListener): void {
    this.routeChangeListeners = this.routeChangeListeners.filter(
      (listener) => listener !== externalListener
    );
  }

  public goTo(
    route: INavigatorRoute | string,
    params: IQueryParams | null = {},
    options: IGoToOptions = {}
  ): void {
    const to =
      typeof route === "object"
        ? route
        : {
            pathname: route || this.getCurrentPath(),
            search: queryString.stringify(
              { ...params },
              Navigator.defaultStringifyOptions
            ),
            hash: options.hash || undefined,
          };

    this.router.history[options.replace ? "replace" : "push"](to);
  }

  public goBack(): void {
    this.router.history.goBack();
  }

  public getCurrentRoute(): IExtendedRoute {
    return { ...this.currentRoute };
  }

  public getCurrentLocation(): Location {
    return this.router.history.location;
  }

  public getCurrentPath(): string {
    const currentPath = this.router.history.location.pathname;

    if (currentPath.slice(-1) === "/" && currentPath.length > 1) {
      return currentPath.slice(0, -1);
    }

    return currentPath;
  }

  public getCurrentSearch(): string {
    const search = this.router.history.location.search;

    return search ? search.slice(1) : "";
  }

  public getCurrentQuery(): ParsedQuery {
    return queryString.parse(
      this.router.history.location.search || "",
      Navigator.defaultParseOptions
    );
  }

  public setQuery(query: IQueryParams | null, options?: IGoToOptions): void {
    const path = this.getCurrentPath();
    const currentQuery = this.getCurrentQuery();
    const nextQuery = query ? Object.assign(currentQuery, query) : {};

    this.goTo(path, nextQuery, options);
  }

  public clearSearch(options: IGoToOptions): void {
    this.setQuery(null, {
      ...options,
    });
  }

  public clearHash(): void {
    this.goTo(
      {
        pathname: this.getCurrentPath(),
        search: this.getCurrentSearch(),
        hash: "",
      },
      undefined,
      { replace: true }
    );
  }

  private addListeners(): void {
    this.router.history.listen(this.handleRouteChange);
  }

  private handleRouteChange = (route: Location, action: Action): void => {
    if (action !== "REPLACE") {
      this.scrollManager?.save(action === "PUSH");
    }

    const leavingRoute = { ...this.currentRoute };
    this.currentRoute = this.prepareRoute(route);

    // Call listeners on next tick
    setTimeout(() => {
      for (const listener of this.routeChangeListeners) {
        listener(route, leavingRoute);
      }
    });
  };

  private prepareRoute(location: Location): IExtendedRoute {
    return {
      ...location,
      origin: window.location.origin,
      href: window.location.origin + location.pathname + location.search,
    };
  }
}

export function initNavigator(router: IRouter) {
  navigator = new Navigator(router);
}
