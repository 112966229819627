import React, { FC } from "react";
import { useTranslation } from "i18n";
import cx from "classnames";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import {
  AlertMessage,
  CommonErrorPopup,
  Popup,
  SCREEN_WIDTH_ENUM,
} from "component-library";
import { useDynamicWindowWidthFor } from "shared-hooks";
import {
  cancelSubscription,
  MY_SUBSCRIPTION_QK,
} from "services/api/BillingApi/BillingApi";
import { pushToDataLayer } from "app-shell/GoogleTagManager/GoogleTagManager";
import { TRACKING_EVENT_TYPE } from "app-shell/GoogleTagManager/constants";

import { ReactComponent as ProfileDocIcon } from "component-library/src/images/svg/icons/profiledoc.svg";
import { ReactComponent as SearchIcon } from "component-library/src/images/svg/icons/search.svg";
import { ReactComponent as StarIcon } from "component-library/src/images/svg/icons/star.svg";
import { ReactComponent as TicketsIcon } from "component-library/src/images/svg/icons/ticket.svg";
import { ReactComponent as WalletIcon } from "component-library/src/images/svg/icons/wallet.svg";

import "./styles.scss";

interface Props {
  isOpen: boolean;
  handleChangePlan: () => void;
  setSubscriptionSuccessCancelled: () => void;
  closePopUp: () => void;
  formattedDate: string;
}

const perks = [
  {
    name: "performer:accountSettings.mySubscriptionPage.cancelModal.perks.search",
    icon: SearchIcon,
  },
  {
    name: "performer:accountSettings.mySubscriptionPage.cancelModal.perks.breakdowns",
    icon: ProfileDocIcon,
  },
  {
    name: "performer:accountSettings.mySubscriptionPage.cancelModal.perks.assistance",
    icon: StarIcon,
    iconClassName: "icon",
  },
  {
    name: "performer:accountSettings.mySubscriptionPage.cancelModal.perks.events",
    icon: TicketsIcon,
  },
  {
    name: "performer:accountSettings.mySubscriptionPage.cancelModal.perks.discounts",
    icon: WalletIcon,
  },
];

const CancellationModal: FC<Props> = ({
  isOpen,
  handleChangePlan,
  setSubscriptionSuccessCancelled,
  closePopUp,
  formattedDate,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const isMobileView = useDynamicWindowWidthFor(SCREEN_WIDTH_ENUM.SM);
  const { mutate, isLoading, error } = useMutation<unknown, AxiosError>(
    cancelSubscription,
    {
      onSuccess: () => {
        setSubscriptionSuccessCancelled();
        pushToDataLayer({
          event: TRACKING_EVENT_TYPE.CancelSubscription,
        });
        queryClient.invalidateQueries(MY_SUBSCRIPTION_QK);
      },
    }
  );

  const onSubmitCancelSubscription = () => {
    mutate();
  };

  const primaryCta = {
    name: t("common:button.label.changePlan"),
    click: handleChangePlan,
    type: "primary",
    ctaDisabled: isLoading,
    size: "medium" as const,
  };

  const secondaryCta = {
    name: t("common:button.label.cancelSubscription"),
    click: onSubmitCancelSubscription,
    type: "secondary",
    ctaDisabled: isLoading,
    loading: isLoading,
    size: "medium" as const,
    className: "text-red-cardinal border-red-cardinal",
  };

  const buttons = [primaryCta, secondaryCta];
  const reversedButtons = [...buttons].reverse();

  if (error) {
    return (
      <CommonErrorPopup
        errorStatusCode={error.response?.status}
        popupProps={{ close: closePopUp }}
      />
    );
  }

  return isOpen ? (
    <Popup
      width={{ lg: 6, md: 8 }}
      texts={{ closePopup: t("common:button.label.cancel") }}
      close={closePopUp}
      className="z-50"
    >
      <AlertMessage
        icon="notice"
        texts={{
          title: t(
            "performer:accountSettings.mySubscriptionPage.cancelModal.title"
          ),
          description: t(
            "performer:accountSettings.mySubscriptionPage.cancelModal.description",
            { date: formattedDate }
          ),
        }}
        buttons={isMobileView ? buttons : reversedButtons}
        buttonAlignment="right"
      >
        <div className="flex flex-col gap-4 px-2 py-3 mx-4 border-2 rounded-xl">
          <p className="font-bold">
            {t(
              "performer:accountSettings.mySubscriptionPage.cancelModal.perks.title"
            )}
          </p>
          {perks.map((perk) => (
            <div key={perk.name} className="flex items-center gap-2">
              <div className="self-start p-2 rounded-full bg-color-neutral-three">
                <perk.icon
                  className={cx(
                    "w-5 h-5 stroke-[0.2] text-color-brand-one",
                    perk.iconClassName
                  )}
                />
              </div>
              <span>{t(perk.name)}</span>
            </div>
          ))}
        </div>
      </AlertMessage>
    </Popup>
  ) : null;
};

export default CancellationModal;
