import { parseUrl, stringifyUrl } from "query-string";
import {
  PlanCodePeriod,
  PlanSelectorSearchParams,
} from "component-library/src/components/Organisms/Billing/types";
import applicationMap, { CheckoutType } from "services/applicationMap";
import { AddOnCodes } from "component-library/src/components/Organisms/Billing/constants";

export function getCheckoutLink({
  planCode,
  isReactivation,
  addOns,
}: {
  planCode?: string;
  isReactivation?: boolean;
  addOns?: AddOnCodes[];
} = {}) {
  const param = planCode?.includes(PlanCodePeriod.Annual)
    ? PlanCodePeriod.Annual
    : PlanCodePeriod.Monthly;

  const parsedUrl = parseUrl(
    applicationMap.routes.checkout({
      type: isReactivation ? CheckoutType.reactivation : CheckoutType.renewal,
    })
  );

  if (planCode) {
    parsedUrl.query[PlanSelectorSearchParams.PreSelectedPlan] = param;
  }

  if (addOns) {
    addOns.forEach((addOn) => {
      parsedUrl.query[PlanSelectorSearchParams.PreSelectedAddOns] = addOn;
    });
  }

  const stringifiedUrl = stringifyUrl(parsedUrl);

  return stringifiedUrl;
}
