import { IBreadcrumbBarItem } from "component-library";
import { SubscriptionCodes } from "component-library/src/components/Organisms/Billing/components/AddOnCheckout/SubscriptionPage/constants";
import { AddOnCodes } from "component-library/src/components/Organisms/Billing/constants";
import applicationMap from "services/applicationMap";

export const ANNUAL_PLAN = "annual";

export const GB_COUNTRY_CODE = "GB";
export const DATE_FORMAT = "dd MMM yyyy";

export enum SubscriptionState {
  Active = "active",
  Inactive = "inactive",
  Canceled = "canceled",
}

export enum SubscriptionCalculatedStatus {
  Renewing = "renewing",
  Expired = "expired",
  Current = "current",
}

export enum AddOnCalculatedStatus {
  Active = "active",
  Canceled = "canceled",
  Inactive = "inactive",
}

export enum BreadcrumbSection {
  BackToAccount,
  MyAccount,
  AccountSettings,
  MySubscriptions,
}

export const breadcrumbs: IBreadcrumbBarItem[] = [
  {
    text: "performer:accountSettings.breadcrumb.backToHome",
    url: applicationMap.routes.index(),
  },
  {
    text: "performer:accountSettings.breadcrumb.account",
    url: applicationMap.routes.index(),
  },
  {
    text: "performer:accountSettings.pageTitle",
    url: applicationMap.routes.accountSettings(),
  },
  {
    text: "performer:accountSettings.mySubscription.header",
    url: applicationMap.routes.settingsMySubscription(),
  },
];

export const buyAddOnButtonNamesMap = {
  [AddOnCodes.AnnualBundle]:
    "performer:accountSettings.mySubscriptionPage.buyEcp",
  [AddOnCodes.MonthlyBundle]:
    "performer:accountSettings.mySubscriptionPage.buyEcp",
  [AddOnCodes.FilmMakersAnnual]:
    "performer:accountSettings.mySubscriptionPage.buyFm",
  [AddOnCodes.FilmMakersMonthly]:
    "performer:accountSettings.mySubscriptionPage.buyFm",
};

export const addOnNameMap = {
  [AddOnCodes.AnnualBundle]: "common:billing.planSelector.addOns.ecp.title",
  [AddOnCodes.MonthlyBundle]: "common:billing.planSelector.addOns.ecp.title",
  [AddOnCodes.FilmMakersAnnual]: "common:billing.planSelector.addOns.fm.title",
  [AddOnCodes.FilmMakersMonthly]: "common:billing.planSelector.addOns.fm.title",
};

export const planNameMap = {
  [SubscriptionCodes.Annual_CC]:
    "performer:accountSettings.mySubscriptionPage.planName.mainPerformer",
  [SubscriptionCodes.Monthly_CC]:
    "performer:accountSettings.mySubscriptionPage.planName.mainPerformer",
  [SubscriptionCodes.Annual_DD]:
    "performer:accountSettings.mySubscriptionPage.planName.mainPerformer",
  [SubscriptionCodes.Monthly_DD]:
    "performer:accountSettings.mySubscriptionPage.planName.mainPerformer",
  [SubscriptionCodes.YP_Annual_CC]:
    "performer:accountSettings.mySubscriptionPage.planName.youngPerformer",
  [SubscriptionCodes.YP_Monthly_CC]:
    "performer:accountSettings.mySubscriptionPage.planName.youngPerformer",
  [SubscriptionCodes.YP_Annual_DD]:
    "performer:accountSettings.mySubscriptionPage.planName.youngPerformer",
  [SubscriptionCodes.YP_Monthly_DD]:
    "performer:accountSettings.mySubscriptionPage.planName.youngPerformer",
  [SubscriptionCodes.Graduate_Annual_CC]:
    "performer:accountSettings.mySubscriptionPage.planName.gradPerformer",
  [SubscriptionCodes.Graduate_Monthly_DD]:
    "performer:accountSettings.mySubscriptionPage.planName.gradPerformer",
  [SubscriptionCodes.Graduate_Annual_DD]:
    "performer:accountSettings.mySubscriptionPage.planName.gradPerformer",
};
