import React from "react";
import gtmParts from "react-google-tag-manager";

let GTMDataLayerName = "";

export const enum CustomEvents {}

export interface IGoogleTagManagerProps {
  gtmId: string;
  dataLayerName: string;
  scriptId: string;
  additionalEvents: object;
  previewVariables: string;
  scheme: string;
}

export class GoogleTagManager extends React.Component<IGoogleTagManagerProps> {
  public static defaultProps = {
    additionalEvents: {},
    previewVariables: "",
    scheme: "https:",
  };

  public componentDidMount() {
    const scriptId = this.props.scriptId;
    GTMDataLayerName = this.props.dataLayerName;

    if (!window[GTMDataLayerName]) {
      const gtmScriptNode = document.getElementById(scriptId);

      if (gtmScriptNode) {
        global.eval(gtmScriptNode.textContent as string);
      }
    }
  }

  public render() {
    const {
      gtmId,
      dataLayerName,
      scriptId,
      additionalEvents,
      previewVariables,
      scheme,
    } = this.props;
    const gtm = gtmParts({
      id: gtmId.trim(),
      previewVariables,
      dataLayerName,
      additionalEvents,
      scheme,
    });

    return (
      <React.Fragment>
        {gtm.noScriptAsReact()}
        <div id={scriptId}>{gtm.scriptAsReact()}</div>
      </React.Fragment>
    );
  }
}

export function pushToDataLayer(object: object) {
  if (window[GTMDataLayerName]) {
    window[GTMDataLayerName].push(object);
  }
}
