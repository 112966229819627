import PageKey from "config/PageKey";
import {
  IAgentQuickSearchTranslation,
  ICommonTranslation,
  IMyAccountPageTranslation,
} from "models/translation";

export const commonFallbackTexts = {
  headerLabels: {
    signIn: "Sign in",
    logoLabel: "Spotlight Logo",
    flagLabel: "Switch language",
    burger: {
      labelOpen: "Open Navigation Menu",
      labelClose: "Close Navigation Menu",
    },
  },
};

type DeepRequired<T> = T extends object
  ? { [K in keyof T]-?: DeepRequired<T[K]> }
  : T;

interface IFallbackPageTranslation {
  common: ICommonTranslation;
  [PageKey.MyAccount]: DeepRequired<IMyAccountPageTranslation>;
  [PageKey.AgentQuickSearch]: DeepRequired<IAgentQuickSearchTranslation>;
}

export const fallbackTranslation: IFallbackPageTranslation = {
  common: {},
  [PageKey.AgentQuickSearch]: {
    page: {
      agentQuickSearchPlaceholderText: "Search performer name",
    },
  },
  [PageKey.MyAccount]: {
    common: {
      welcomeBackFormat: "Welcome back {userFullName}",
      quickLinksHeading: "Quick links",
    },
    "service Banner - Performer (default)": {
      serviceBannerPerformerDefaultHeading: "We've updated your tools!",
      serviceBannerPerformerDefaultMedia: null,
      serviceBannerPerformerDefaultBody: "",
    },
    "service Banner - Performer (Add On - Bundle)": {
      serviceBannerPerformerAddOnBundleHeading: "We've updated your tools!",
      serviceBannerPerformerAddOnBundleMedia: null,
      serviceBannerPerformerAddOnBundleBody: "",
    },
    "service Banner - Graduate": {
      serviceBannerGraduateHeading: "We've updated your tools!",
      serviceBannerGraduateMedia: null,
      serviceBannerGraduateBody: "",
    },
    "service Banner - Performer (inactive)": {
      serviceBannerPerformerInactiveHeading: "We've updated your tools!",
      serviceBannerPerformerInactiveMedia: null,
      serviceBannerPerformerInactiveBody: "",
    },
    "service Banner - Young Performer": {
      serviceBannerPerformerYoungHeading: "We've updated your tools!",
      serviceBannerPerformerYoungMedia: null,
      serviceBannerPerformerYoungBody: "",
    },
    "service Banner - Agent": {
      serviceBannerAgentMedia: null,
      serviceBannerAgentHeading: "We've updated your tools!",
      serviceBannerAgentBody: "",
    },
    "service Banner - Casting Director (default)": {
      serviceBannerCastingDirectorDefaultHeading: "We've updated your tools!",
      serviceBannerCastingDirectorDefaultBody: "",
      serviceBannerCastingDirectorDefaultMedia: null,
    },
    "service Banner - Casting Director (inactive)": {
      serviceBannerCastingDirectorInactiveHeading: "We've updated your tools!",
      serviceBannerCastingDirectorInactiveBody: "",
      serviceBannerCastingDirectorInactiveMedia: null,
    },
    "service Banner - Casting Director (search only)": {
      serviceBannerCastingDirectorSearchOnlyHeading:
        "We've updated your tools!",
      serviceBannerCastingDirectorSearchOnlyMedia: null,
      serviceBannerCastingDirectorSearchOnlyBody: "",
    },
    "account Status Banner": {
      accountStatusBannerCastingDirectorExpired: "Your account has expired",
      accountStatusBannerPerformerNeedsRenewing: "It's time to renew",
      accountStatusBannerPerformerExpired: "Your account has expired",
      accountStatusBannerCastingDirectorNeedsRenewing: "It's time to renew",
      accountStatusBannerPerformerSuspended: "Your account is suspended",
      accountStatusBannerPerformerExpiredAndNameTaken:
        "Your account has expired.",
      accountStatusBannerGraduatePerformerNeedsRenewing: "It's time to renew",
      accountStatusBannerGraduatePerformerExpired: "Your account has expired",
      accountStatusBannerDisableOnlineReactivate: "Your account has expired ",
      accountStatusBannerRenewButtonText: "Renew",
      accountStatusBannerReactivateNowButtonText: "Reactive",
      accountStatusBannerContactUsButtonText: "Contact us",
      accountStatusBannerSignOutButtonText: "Sign out",
    },
    "marketing Banner - Agent": {
      marketingBannerAgentBody: "",
      marketingBannerAgentCallToActionText: "",
      marketingBannerAgentHeading: "",
      marketingBannerAgentBackgroundImage: null,
      marketingBannerAgentCallToActionUrl: "",
    },
    "marketing Banner - Casting Director": {
      marketingBannerCastingDirectorHeading: "",
      marketingBannerCastingDirectorCallToActionText: "",
      marketingBannerCastingDirectorBody: "",
      marketingBannerCastingDirectorBackgroundImage: null,
      marketingBannerCastingDirectorCallToActionUrl: "",
    },
    "marketing Banner - Performer": {
      marketingBannerPerformerBody: "",
      marketingBannerPerformerHeading: "",
      marketingBannerPerformerCallToActionUrl: "",
      marketingBannerPerformerBackgroundImage: null,
      marketingBannerPerformerCallToActionText: "",
    },
    "marketing Banner - Performer (Add On - Bundle)": {
      marketingBannerPerformerAddOnBundleHeading: "",
      marketingBannerPerformerAddOnBundleBody: "",
      marketingBannerPerformerAddOnBundleCallToActionText: "",
      marketingBannerPerformerAddOnBundleCallToActionUrl: "",
      marketingBannerPerformerAddOnBundleBackgroundImage: null,
    },
    "marketing Banner - Graduate": {
      marketingBannerGraduateHeading: "",
      marketingBannerGraduateBody: "",
      marketingBannerGraduateCallToActionText: "",
      marketingBannerGraduateCallToActionUrl: "",
      marketingBannerGraduateBackgroundImage: null,
    },
    ["performer Search"]: {
      searchLabelYoungPerformer: "Young performer",
      searchLabelGraduate: "Graduate",
      searchLabelPerformer: "Performer",
      searchPlaceholderText: "Search performers",
      searchShowMoreResultsFor: "Show more results for '{searchTerm}'",
      searchNoResultsFound: "No results found",
    },
  },
};
