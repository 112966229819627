import React, { FC } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Spinner } from "component-library";
import { CheckoutAccessTypes } from "component-library/src/components/Organisms/Billing/types";
import { FETCH_ACCESS_TO_CHECKOUT } from "component-library/src/components/Organisms/Billing/services";
import { BillingUser } from "translations/src/models/billing";
import Layout from "app-shell/Layout/Layout";
import CDWrapper from "pages/CheckoutPageWrapper/variants/CDWrapper";
import GradTransferWrapper from "pages/CheckoutPageWrapper/variants/GradTransferWrapper";
import PerformerWrapper from "pages/CheckoutPageWrapper/variants/PerformerWrapper";
import useGetUserType from "./hooks/useGetUserType";
import { NonJoiningBillingUser } from "./types";
import "../Checkout/styles.scss";
import {
  getRecurlyDetails,
  IBillingApiResponse,
  MY_SUBSCRIPTION_QK,
} from "services/api/BillingApi/BillingApi";
import { SubscriptionCalculatedStatus } from "app/constants";
import { BrazeCustomEvents } from "shared-services";

const renderComponentMap: Record<NonJoiningBillingUser, any> = {
  [BillingUser.Casting]: CDWrapper,
  [BillingUser.Performer]: PerformerWrapper,
  [BillingUser.YoungPerformer]: PerformerWrapper,
  [BillingUser.GraduateTransfer]: GradTransferWrapper,
  [BillingUser.Graduate]: PerformerWrapper,
};

const CheckoutPageWrapper: FC = ({ children }) => {
  const queryClient = useQueryClient();

  const { data: mySubscriptionData, isLoading } = useQuery<
    IBillingApiResponse | undefined
  >({
    queryKey: MY_SUBSCRIPTION_QK,
    queryFn: async () => await getRecurlyDetails(),
    cacheTime: 0,
  });

  const accessData = queryClient.getQueryData<{
    data: { checkoutType: CheckoutAccessTypes };
  }>([FETCH_ACCESS_TO_CHECKOUT, true]);

  const userType = useGetUserType(
    accessData?.data.checkoutType ?? CheckoutAccessTypes.RenewalReactivation
  );

  const isRenewal =
    mySubscriptionData?.subscription?.calculatedStatus ===
    SubscriptionCalculatedStatus.Renewing;

  const brazeSubscriptionPurchaseType = isRenewal
    ? BrazeCustomEvents.SubscriptionPurchaseRenewal
    : BrazeCustomEvents.SubscriptionPurchaseReactivation;
  const brazeAddOnPurchaseType = isRenewal
    ? BrazeCustomEvents.AddOnBundlePurchaseRenewal
    : BrazeCustomEvents.AddOnBundlePurchaseReactivation;

  const Component = renderComponentMap[userType as NonJoiningBillingUser];

  if (!userType || isLoading) return <Spinner />;

  return (
    <Layout hideFooter contentContainerCustomClassName="h-full">
      <div className="pt-2 md:px-2 checkout-page">
        {children ?? (
          <Component
            brazeSubscriptionPurchaseType={brazeSubscriptionPurchaseType}
            brazeAddOnPurchaseType={brazeAddOnPurchaseType}
          />
        )}
      </div>
    </Layout>
  );
};

export default CheckoutPageWrapper;
