/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Fragment, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormikProps } from "formik";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Trans, useTranslation } from "i18n";
import { useDispatch } from "react-redux";

import UserAccountService from "services/api/UserAccount/UserAccountService";
import applicationMap from "services/applicationMap";
import PageWrapperWithinLayout from "app-shell/PageWrapperWithinLayout/PageWrapperWithinLayout";
import LabelValueRow from "components/LabelValueRow/LabelValueRow";
import GlobalSpinner from "components/GlobalSpinner/GlobalSpinner";
import {
  emailValidationSchema,
  generateBreadcrumbsData,
  getTootlipProps,
  Head,
  QuestionTooltip,
  SelectFormik,
  TextInputFormik,
} from "component-library";
import { ReactComponent as EyeOpenIcon } from "component-library/src/images/svg/icons/eyeopen.svg";
import { ReactComponent as EyeClosedIcon } from "component-library/src/images/svg/icons/eyeclosed.svg";

import {
  passwordValidationSchema,
  securityQuestionValidationSchema,
} from "./validationSchema";
import {
  FormFields,
  SECURITY_QUESTION_OPTIONS_QUERY_KEY,
  USER_DETAILS_QUERY_KEY,
} from "./constants";
import { hidePopup, showPopup } from "store/ui/ui.actions";
import { PopupType } from "store/ui/ui.types";
import { useNewAccSettingsPageAccessCheck } from "../parts/hooks/useNewAccSettingsPageAccessCheck";
import FormSection from "../parts/FormSection";
import { breadcrumbs, BreadcrumbSection } from "app/constants";

export interface IEmailFormFields {
  email: string;
}

interface IPasswordFormFields {
  password: string;
  confirmPassword: string;
}

interface ISecurityQuestionFormFields {
  question: string;
  answer: string;
}

const LoginDetails = () => {
  useNewAccSettingsPageAccessCheck();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isPasswordInputVisible, setIsPasswordInputVisible] = useState({
    password: false,
    confirmPassword: false,
  });
  const emailFormRef = useRef<FormikProps<IEmailFormFields> | null>(null);
  const passwordFormRef = useRef<FormikProps<IPasswordFormFields> | null>(null);
  const securityQuestionFormRef =
    useRef<FormikProps<ISecurityQuestionFormFields> | null>(null);

  const { data: userData } = useQuery(USER_DETAILS_QUERY_KEY, () =>
    UserAccountService.getUserDetailsForSettingsPage()
  );

  const { data: securityQuestionOptions } = useQuery({
    queryKey: SECURITY_QUESTION_OPTIONS_QUERY_KEY,
    queryFn: () => UserAccountService.getSecurityQuestions(),
    select: (data) =>
      data?.map((question: string) => ({ label: question, value: question })),
  });

  const { mutate: updateEmail, isLoading: isUpdateEmailLoading } = useMutation({
    mutationFn: (value: string) => UserAccountService.updateUserEmail(value),
    onSuccess: () => {
      queryClient.invalidateQueries(USER_DETAILS_QUERY_KEY);
    },
  });

  const { mutate: resendEmailVerification } = useMutation((value: string) =>
    UserAccountService.resendEmailVerification(value)
  );

  const { mutate: cancelEmailVerification, isLoading: isCancelEmailLoading } =
    useMutation({
      mutationFn: (value: string) =>
        UserAccountService.cancelEmailVerification(value),
      onSuccess: () => {
        queryClient.invalidateQueries(USER_DETAILS_QUERY_KEY);
      },
    });

  const { mutate: updatePassword, isLoading: isUpdatePasswordLoading } =
    useMutation((value: string) =>
      UserAccountService.updateUserPassword(value)
    );

  const {
    mutate: updateSecurityQuestion,
    isLoading: isUpdateSecurityQuestionLoading,
  } = useMutation((values: ISecurityQuestionFormFields) =>
    UserAccountService.updateUserSecurityQuestion(values)
  );

  const handleSubmitEmail = (values: IEmailFormFields) => {
    if (values.email.toLowerCase() === userData?.user.email?.toLowerCase())
      return;

    dispatch(
      showPopup({
        type: PopupType.ErrorPopup,
        data: {
          title: t("performer:accountSettings.loginDetails.popup.title"),
          description: t(
            "performer:accountSettings.loginDetails.popup.description",
            { email: values.email }
          ),
          closeButtonText: t("common:button.label.ok"),
          hidePopup: () => {
            updateEmail(values.email);
            dispatch(hidePopup());
          },
        },
      })
    );
  };

  const handleResendEmailVerification = (email: string) => {
    resendEmailVerification(email);
  };

  const handleCancelEmailVerification = (email: string) => {
    cancelEmailVerification(email);
  };

  const handleSubmitPassword = (values: IPasswordFormFields) => {
    if (values.confirmPassword !== values.password) return;

    updatePassword(values.password);
  };

  const handleSubmitSecurityQuestion = (
    values: ISecurityQuestionFormFields
  ) => {
    updateSecurityQuestion(values);
  };

  const handleToggleShowPassword = (key: string) => {
    setIsPasswordInputVisible((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const isLoading =
    isUpdateEmailLoading ||
    isCancelEmailLoading ||
    isUpdatePasswordLoading ||
    isUpdateSecurityQuestionLoading;

  const eyeIconProps = {
    className: "text-color-brand-one",
  };

  if (isLoading) {
    return <GlobalSpinner />;
  }

  return (
    <>
      <Head>
        <title>{t("performer:accountSettings.loginDetails.title")}</title>
      </Head>
      <PageWrapperWithinLayout
        pageHeader={t("performer:accountSettings.loginDetails.title")}
        subFooter={{
          text: t("performer:accountSettings.loginDetails.closeButton"),
          onClick: () => history.push(applicationMap.routes.accountSettings()),
        }}
        breadcrumbs={generateBreadcrumbsData(breadcrumbs, [
          BreadcrumbSection.MyAccount,
          BreadcrumbSection.AccountSettings,
        ])}
      >
        <div className="p-2">
          <p className="my-2 lg:w-3/4">
            <Trans
              i18nKey="performer:accountSettings.loginDetails.description"
              components={{
                bold: (
                  <a
                    target="_blank"
                    href="https://support.spotlight.com/hc/en-us"
                    className="font-semibold"
                    rel="noreferrer"
                  />
                ),
              }}
            />
          </p>
          <div className="mb-3">
            <LabelValueRow
              label={t("performer:accountSettings.loginDetails.usernameLabel")}
              value={userData?.user.username?.toUpperCase() || ""}
            />
          </div>
          <hr />
          <div className="py-4 leading-7">
            <div className="mb-4 md:mb-2">
              <h2 className="text-[26px]">
                {t("performer:accountSettings.loginDetails.updateEmail")}
              </h2>
              <Trans
                i18nKey="performer:accountSettings.loginDetails.currentEmail"
                components={{ bold: <span className="font-semibold" /> }}
                values={{ email: userData?.user.email }}
                className="leading-6 md:leading-10"
              />
            </div>
            {userData?.items &&
              userData.items
                .filter((item) => !item.verified)
                .map((item) => (
                  <Fragment key={item.email}>
                    <Trans
                      i18nKey="performer:accountSettings.loginDetails.unverifiedEmail"
                      components={{
                        bold: <span className="font-semibold" />,
                        resend: (
                          <a
                            onClick={() =>
                              handleResendEmailVerification(item.email)
                            }
                          />
                        ),
                        cancel: (
                          <a
                            onClick={() =>
                              handleCancelEmailVerification(item.email)
                            }
                          />
                        ),
                      }}
                      values={{ email: item.email }}
                      className="leading-6 md:leading-10"
                    />
                    <br />
                  </Fragment>
                ))}
            <FormSection<IEmailFormFields>
              initialValues={{ email: "" }}
              innerRef={emailFormRef}
              handleSubmit={handleSubmitEmail}
              validationSchema={() =>
                emailValidationSchema(t, FormFields.Email)
              }
              buttonText={t("common:button.label.updateEmail")}
            >
              <TextInputFormik
                className="w-full"
                label={t(
                  "performer:accountSettings.loginDetails.updateEmailLabel"
                )}
                name={FormFields.Email}
                tooltipComponent={() => (
                  <QuestionTooltip
                    tooltipProps={getTootlipProps(
                      t(
                        "performer:accountSettings.loginDetails.tooltip.email.header"
                      ),
                      t(
                        "performer:accountSettings.loginDetails.tooltip.email.body"
                      ),
                      true
                    )}
                  />
                )}
              />
            </FormSection>
          </div>
          <hr />
          <div className="py-4 leading-7">
            <div className="mb-4 md:mb-2">
              <h2 className="text-[26px]">
                {t("performer:accountSettings.loginDetails.updatePassword")}
              </h2>
            </div>
            <FormSection<IPasswordFormFields>
              innerRef={passwordFormRef}
              initialValues={{
                password: "",
                confirmPassword: "",
              }}
              handleSubmit={handleSubmitPassword}
              validationSchema={() =>
                passwordValidationSchema(t, userData?.user.username || "")
              }
              buttonText={t("common:button.label.updatePassword")}
            >
              <div className="w-full">
                <TextInputFormik
                  label={t("common:label.alternative.updatePasswordColon")}
                  name={FormFields.Password}
                  password={!isPasswordInputVisible.password}
                  tooltipComponent={() => (
                    <QuestionTooltip
                      tooltipProps={getTootlipProps(
                        t(
                          "performer:accountSettings.loginDetails.tooltip.newPassword.header"
                        ),
                        t(
                          "performer:accountSettings.loginDetails.tooltip.newPassword.body"
                        )
                      )}
                    />
                  )}
                >
                  <button
                    onClick={() =>
                      handleToggleShowPassword(FormFields.Password)
                    }
                    type="button"
                    className="absolute inset-y-0 right-1"
                  >
                    {isPasswordInputVisible[FormFields.Password] ? (
                      <EyeClosedIcon {...eyeIconProps} />
                    ) : (
                      <EyeOpenIcon {...eyeIconProps} />
                    )}
                  </button>
                </TextInputFormik>

                <TextInputFormik
                  label={t("common:label.alternative.confirmPasswordColon")}
                  name={FormFields.ConfirmPassword}
                  password={!isPasswordInputVisible.confirmPassword}
                >
                  <button
                    onClick={() =>
                      handleToggleShowPassword(FormFields.ConfirmPassword)
                    }
                    type="button"
                    className="absolute inset-y-0 right-1"
                  >
                    {isPasswordInputVisible[FormFields.ConfirmPassword] ? (
                      <EyeClosedIcon {...eyeIconProps} />
                    ) : (
                      <EyeOpenIcon {...eyeIconProps} />
                    )}
                  </button>
                </TextInputFormik>
              </div>
            </FormSection>
          </div>
        </div>
        <hr />
        <div className="py-4 leading-7">
          <div className="mb-4 md:mb-2">
            <h2 className="text-[26px]">
              {t(
                "performer:accountSettings.loginDetails.updateSecurityQuestion"
              )}
            </h2>
          </div>
          <FormSection<ISecurityQuestionFormFields>
            innerRef={securityQuestionFormRef}
            initialValues={{
              question: "",
              answer: "",
            }}
            validationSchema={securityQuestionValidationSchema}
            handleSubmit={handleSubmitSecurityQuestion}
            buttonText={t("common:button.label.updateSq")}
          >
            <div className="flex flex-col w-full">
              <div className="flex ">
                <SelectFormik
                  label={t(
                    "performer:accountSettings.loginDetails.updateSecurityQuestionLabel"
                  )}
                  name={FormFields.SecurityQuestion}
                  placeholder={t("common:placeholder.securityQuestion")}
                  tooltipComponent={() => (
                    <QuestionTooltip
                      tooltipProps={getTootlipProps(
                        t(
                          "performer:accountSettings.loginDetails.updateSecurityQuestionTooltip.header"
                        ),
                        t(
                          "performer:accountSettings.loginDetails.updateSecurityQuestionTooltip.content"
                        )
                      )}
                    />
                  )}
                  options={securityQuestionOptions || []}
                  className="w-full"
                />
              </div>
              <div className="flex w-full">
                <TextInputFormik
                  label={t(
                    "performer:accountSettings.loginDetails.enterAnswerLabel"
                  )}
                  name={FormFields.SecurityAnswer}
                  tooltipComponent={() => (
                    <QuestionTooltip
                      tooltipProps={getTootlipProps(
                        t(
                          "performer:accountSettings.loginDetails.enterAnswerTooltip.header"
                        ),
                        t(
                          "performer:accountSettings.loginDetails.enterAnswerTooltip.content"
                        )
                      )}
                    />
                  )}
                  className="w-full"
                />
              </div>
            </div>
          </FormSection>
        </div>
      </PageWrapperWithinLayout>
    </>
  );
};

export default LoginDetails;
