import React, { FC, useContext } from "react";
import { useTranslation } from "i18n";
import { AxiosError } from "axios";
import {
  AlertMessage,
  BillingContext,
  BillingContextProvider,
  CommonErrorPopup,
  isSmallDeviceWidth,
  Popup,
} from "component-library";
import { BillingUser } from "translations/src/models/billing";
import {
  CheckoutFlow,
  currencyMap,
  CurrencyType,
} from "component-library/src/components/Organisms/Billing/types";
import PriceInfo from "component-library/src/components/Organisms/Billing/components/AddOnCheckout/PriceInfo";
import { useGetExistingPaymentMethod } from "component-library/src/components/Organisms/Billing/hooks/useGetExistingPaymentMethod";
import { SubscriptionCodes } from "component-library/src/components/Organisms/Billing/components/AddOnCheckout/SubscriptionPage/constants";
import { AddOnCodes } from "component-library/src/components/Organisms/Billing/constants";
import ExistingPaymentMethodBlock from "component-library/src/components/Organisms/Billing/components/ExistingPaymentMethodBlock/ExistingPaymentMethodBlock";
import { useMutation, useQueryClient } from "react-query";
import { pushToDataLayer } from "app-shell/GoogleTagManager/GoogleTagManager";
import { TRACKING_EVENT_TYPE } from "app-shell/GoogleTagManager/constants";
import {
  MY_SUBSCRIPTION_QK,
  revertSubscription,
} from "services/api/BillingApi/BillingApi";
import { useGetSubscriptions } from "component-library/src/components/Organisms/Billing/hooks/useGetSubscriptions";
import { planNameMap } from "app/constants";

interface IReinstateModalProps {
  isOpen: boolean;
  closePopUp: () => void;
  onSuccessCallback: () => void;
  planCode: SubscriptionCodes;
  formattedDate: string;
  activeAddOns: AddOnCodes[];
}

const ReinstateModal: FC<IReinstateModalProps> = ({
  isOpen,
  closePopUp,
  onSuccessCallback,
  planCode,
  formattedDate,
  activeAddOns,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { planPeriodData } = useContext(BillingContext);
  const isMobile = isSmallDeviceWidth();

  const { data: subscriptionsData, isLoading: subscriptionsDataIsLoading } =
    useGetSubscriptions();
  const subscription = subscriptionsData?.find((sub) => sub.code === planCode);
  const addOns = subscription?.addOns.filter((addOn) =>
    activeAddOns.includes(addOn.code)
  );
  const planName = planNameMap[subscription?.code as keyof typeof planNameMap];
  const totalPrice = addOns?.reduce(
    (acc, addOn) => acc + addOn.totalValue,
    subscription?.totalValue ?? 0
  );

  const { data: paymentMethodData } = useGetExistingPaymentMethod(true);

  const { mutate, isLoading, error } = useMutation<unknown, AxiosError>(
    revertSubscription,
    {
      onSuccess: () => {
        onSuccessCallback();
        pushToDataLayer({
          event: TRACKING_EVENT_TYPE.ReactivateSubscription,
        });
        queryClient.invalidateQueries(MY_SUBSCRIPTION_QK);
      },
    }
  );

  const onSubmitReinstateSubscription = () => {
    mutate();
  };

  if (error) {
    return (
      <CommonErrorPopup
        errorStatusCode={error.response?.status}
        popupProps={{ close: closePopUp }}
      />
    );
  }

  return isOpen ? (
    <Popup width={{ lg: 8, md: 8 }} close={closePopUp} className="z-50">
      <AlertMessage
        icon="notice"
        texts={{
          title: t(
            "performer:accountSettings.mySubscriptionPage.reinstateModal.title"
          ),
          description: t(
            "performer:accountSettings.mySubscriptionPage.reinstateModal.description"
          ),
        }}
        buttons={[
          {
            name: t(
              "performer:accountSettings.mySubscriptionPage.canceled.reinstateButton"
            ),
            click: onSubmitReinstateSubscription,
            type: "primary",
            size: "medium",
            loading: isLoading,
          },
        ]}
        buttonAlignment="right"
      >
        <BillingContextProvider
          billingUserType={BillingUser.Performer}
          checkoutFlow={CheckoutFlow.SubscriptionsModals}
          disableDiscountCodeField
          presetExistingPaymentMethod
        >
          <div className="flex flex-col gap-4 px-2 py-3 rounded-xl bg-color-neutral-three">
            <PriceInfo
              addOns={addOns ?? []}
              planName={planPeriodData?.selectedPeriod || ""}
              currencySymbol={currencyMap[CurrencyType.GBP]}
              placeholderSubscription={subscription}
              isAnnual
              showButton={false}
              title={
                subscriptionsDataIsLoading
                  ? t("common:billing.checkout.orderSummary.loadingPrice")
                  : t(planName)
              }
            />
            <div className="flex justify-between w-full">
              <p className="text-2xl">
                {isMobile
                  ? t(
                      "performer:accountSettings.mySubscriptionPage.reinstateModal.totalMobile"
                    )
                  : t(
                      "performer:accountSettings.mySubscriptionPage.reinstateModal.totalDesktop",
                      { date: formattedDate }
                    )}
              </p>
              <p className="text-2xl">
                {subscription?.currencySymbol}
                {totalPrice?.toFixed(2)}*
              </p>
            </div>
            {isMobile && (
              <p className="-my-2">
                {t(
                  "performer:accountSettings.mySubscriptionPage.reinstateModal.nextBillMobile",
                  { date: formattedDate }
                )}
              </p>
            )}
            <hr />
            <div className="flex flex-col gap-2 md:flex-row md:justify-between">
              <p>
                {t(
                  "performer:accountSettings.mySubscriptionPage.reinstateModal.paymentMethod"
                )}
              </p>
              <ExistingPaymentMethodBlock
                data={paymentMethodData}
                type="compact"
              />
            </div>
            <p className="text-sm text-color-neutral-two">
              {t(
                "performer:accountSettings.mySubscriptionPage.reinstateModal.disclaimer"
              )}
            </p>
          </div>
        </BillingContextProvider>
      </AlertMessage>
    </Popup>
  ) : null;
};

export default ReinstateModal;
