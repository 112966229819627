import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "i18n";
import { useStepTracking } from "shared-hooks";
import { TRACKING_EVENT_TYPE } from "app-shell/GoogleTagManager/constants";
import { pushToDataLayer } from "app-shell/GoogleTagManager/GoogleTagManager";
import { BillingContextProvider, StepTracker } from "component-library";
import {
  CheckoutAccessTypes,
  IAddOns,
} from "component-library/src/components/Organisms/Billing/types";
import { getGraduateStepTrackingItems } from "pages/TransferProfilePage/constants";
import {
  ITransferSubscriptionPayload,
  transferSubscriptionToGrad,
} from "services/api/BillingApi/BillingApi";
import applicationMap from "services/applicationMap";
import useAddOnCheckoutSteps from "../hooks/useAddOnCheckoutPages";
import { useGetBillingContextDefaultProps } from "../hooks/useGetBillingContextDefaultProps";
import { CheckoutBrazeEventTypes } from "pages/CheckoutPageWrapper/types";
import { logCustomEvent as brazeCustomEvent } from "@braze/web-sdk";

interface Props extends CheckoutBrazeEventTypes {}

export default function GradTransferWrapper({
  brazeSubscriptionPurchaseType,
  brazeAddOnPurchaseType,
}: Props) {
  const history = useHistory();
  const defaultProps = useGetBillingContextDefaultProps(
    CheckoutAccessTypes.GradsTransfer
  );
  const { t } = useTranslation();
  const { Component, setAddOnCheckoutStep, addOnCheckoutStep } =
    useAddOnCheckoutSteps();
  const { pathname } = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const applicationId = urlParams.get("applicationId");

  const showStepTracker = !pathname.includes("complete");
  const steps = useStepTracking({
    stepNumber: addOnCheckoutStep + 1,
    stepTrackingItems: getGraduateStepTrackingItems(t),
    disableAllSteps: true,
  });

  const handleGoBack = () => {
    if (addOnCheckoutStep) return setAddOnCheckoutStep(0);

    history.push(applicationMap.routes.graduatesTransferInformation());
  };

  const handleGoNext = () => {
    if (!addOnCheckoutStep) setAddOnCheckoutStep(1);
  };

  const postCheckoutCallback = async (
    data: any,
    trackingData: Record<string, any>
  ) => {
    await transferSubscriptionToGrad(
      data as ITransferSubscriptionPayload,
      `/application/graduate/transfer/${applicationId}/complete`
    );

    pushToDataLayer({
      event: TRACKING_EVENT_TYPE.ReactivateRenew,
      memberTier: data?.planTypeFilter,
      ...trackingData,
    });

    brazeCustomEvent(brazeSubscriptionPurchaseType, {
      memberType: trackingData.memberType,
      paymentPeriod: trackingData.paymentPeriod,
      revenue: trackingData.paymentValue,
    });

    trackingData?.addOnData?.forEach((addOn: IAddOns) => {
      brazeCustomEvent(brazeAddOnPurchaseType, {
        memberType: trackingData.memberType,
        paymentPeriod: addOn.code,
        revenue: addOn.amount,
      });
    });
  };

  return (
    <>
      {showStepTracker && <StepTracker items={steps} />}
      <BillingContextProvider
        {...defaultProps}
        goBack={handleGoBack}
        goNext={handleGoNext}
        postCheckoutCallback={postCheckoutCallback}
      >
        <Component />
      </BillingContextProvider>
    </>
  );
}
