import React, { FC, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Form, Formik, FormikProps } from "formik";
import { useTranslation, Trans } from "i18n";
import { authorizationService } from "shared-auth";
import {
  PhoneFieldFormik,
  TextInputFormik,
  SelectCountryFormik,
  SelectStateProvinceFormik,
  Button,
  Spinner,
  COUNTRIES_WITH_STATE_PROVINCE,
  QuestionTooltip,
  getTootlipProps,
  generateBreadcrumbsData,
  Head,
} from "component-library";
import { dataTestIds } from "data-testids";
import PageWrapperWithinLayout from "app-shell/PageWrapperWithinLayout";
import useGetAddressDetail from "./hooks/useGetAddressDetail";
import { IAddress, updateAddress } from "services/api/BillingApi/BillingApi";
import { FieldNames } from "pages/AccountSettings/MyDetails/constants";
import { validationSchema } from "./validationSchema";
import applicationMap from "services/applicationMap";
import { useNewAccSettingsPageAccessCheck } from "pages/AccountSettings/parts/hooks/useNewAccSettingsPageAccessCheck";
import { useReturnUrl } from "shared-hooks";
import { breadcrumbs, BreadcrumbSection } from "app/constants";
import "./styles.scss";

const defaultInitialValues = {
  [FieldNames.city]: "",
  [FieldNames.country]: "",
  [FieldNames.region]: "",
  [FieldNames.street1]: "",
  [FieldNames.street2]: "",
  [FieldNames.postalCode]: "",
  [FieldNames.phone]: "",
};

const MyDetails: FC = () => {
  useNewAccSettingsPageAccessCheck();
  const history = useHistory();
  const formRef = useRef<FormikProps<IAddress>>(null);
  const { t } = useTranslation();
  const { data, isLoading } = useGetAddressDetail();
  const initialValues = { ...defaultInitialValues, ...data?.address };
  const userName = authorizationService.user?.profile.name;
  const { getReturnUrl, redirectToReturnUrl } = useReturnUrl();

  const goToMyAccount = (hasAddressChanged: boolean) => {
    if (getReturnUrl() && hasAddressChanged) {
      redirectToReturnUrl();
    } else {
      history.replace(applicationMap.routes.accountSettings());
    }
  };

  const submit = async (values: IAddress) => {
    const result = await updateAddress({
      ...values,
      stateProvince: values.region,
    });

    if (result.data?.RecurlyError) {
      const postalCodeError = result.data?.RecurlyError?.params.find(
        ({ param }: { param: string }) => param === "address.postal_code"
      );

      if (postalCodeError) {
        formRef?.current?.setFieldError(
          FieldNames.postalCode,
          t("common:validation.notValid", {
            field: t("common:label.zipPostCodeColon"),
          })
        );
      }

      return;
    }

    goToMyAccount(true);
  };

  if (isLoading) return <Spinner />;

  return (
    <>
      <Head>
        <title>{t("performer:accountSettings.myDetails.header")}</title>
      </Head>
      <PageWrapperWithinLayout
        pageHeader={t("performer:accountSettings.myDetails.header")}
        subFooter={{
          text: t("common:button.label.close"),
          onClick: () => goToMyAccount(false),
        }}
        breadcrumbs={generateBreadcrumbsData(breadcrumbs, [
          BreadcrumbSection.MyAccount,
          BreadcrumbSection.AccountSettings,
        ])}
      >
        <Formik<IAddress>
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema(t)}
          onSubmit={submit}
        >
          {({ values, submitForm, isSubmitting }) => {
            return (
              <div className="py-5 my-details-page">
                <div className="pb-5">
                  <Trans
                    i18nKey="performer:accountSettings.myDetails.pageDescription"
                    components={[
                      <br key="br" />,
                      <b key="b" />,
                      <a
                        href="https://support.spotlight.com/hc/en-us"
                        target="_blank"
                        rel="noreferrer"
                        key="a"
                      />,
                    ]}
                  />
                </div>
                <Form>
                  <div className="border-b-[1px] mb-4 border-grey-light-2">
                    <div className="max-w-[440px]">
                      <div className="flex justify-between pb-5 mb-5 border-b-[1px] border-grey-light-2">
                        <div className="font-semibold">
                          {t("common:label.name")}
                        </div>
                        <div className="mr-20">{userName}</div>
                      </div>
                      <div>
                        <div className="section-title">
                          {t(
                            "performer:accountSettings.myDetails.provideYourPhone"
                          )}
                        </div>
                        <PhoneFieldFormik
                          name={FieldNames.phone}
                          label={t("common:label.mobileNumberColon")}
                          tooltipComponent={() => (
                            <QuestionTooltip
                              tooltipProps={getTootlipProps(
                                t(
                                  "performer:accountSettings.myDetails.tooltip.mobileNumberHeader"
                                ),
                                t(
                                  "performer:accountSettings.myDetails.tooltip.mobileNumberBody"
                                )
                              )}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="max-w-[440px]">
                    <div>
                      <div className="section-title">
                        {t(
                          "performer:accountSettings.myDetails.provideYourAddress"
                        )}
                      </div>
                      <TextInputFormik
                        name={FieldNames.street1}
                        label={t("common:label.address1Colon")}
                        tooltipComponent={() => (
                          <QuestionTooltip
                            tooltipProps={getTootlipProps(
                              t(
                                "performer:accountSettings.myDetails.tooltip.address1Header"
                              ),
                              t(
                                "performer:accountSettings.myDetails.tooltip.address1Body"
                              )
                            )}
                          />
                        )}
                      />
                      <TextInputFormik
                        name={FieldNames.street2}
                        label={t("common:label.address2OptionalColon")}
                      />
                      <TextInputFormik
                        name={FieldNames.city}
                        label={t("common:label.cityColon")}
                      />
                      <TextInputFormik
                        name={FieldNames.postalCode}
                        label={t("common:label.zipPostCodeColon")}
                      />
                      <SelectCountryFormik
                        name={FieldNames.country}
                        label={t("common:label.countryColon")}
                        placeholder={t("common:placeholder.selectCountry")}
                        searchableBySearchbox
                      />
                      {COUNTRIES_WITH_STATE_PROVINCE.includes(
                        values.country
                      ) && (
                        <SelectStateProvinceFormik
                          name={FieldNames.region}
                          selectedCountryCode={values.country}
                          label={t("common:label.stateProvinceColon")}
                          placeholder={t(
                            "common:placeholder.selectStateProvince"
                          )}
                        />
                      )}
                    </div>
                  </div>
                </Form>
                <div className="flex justify-end">
                  <Button
                    type="primary"
                    text={t("common:button.label.updateDetails")}
                    className="max-w-[330px]"
                    onClick={submitForm}
                    loading={isSubmitting}
                    data-testid={
                      dataTestIds.userAccountApp[
                        "AccountSettings.MyDetails.MyDetails.updateDetailsButton"
                      ]
                    }
                  />
                </div>
              </div>
            );
          }}
        </Formik>
      </PageWrapperWithinLayout>
    </>
  );
};

export default MyDetails;
