import React from "react";
import cx from "classnames";
import { useTranslation } from "i18n";
import { Pill } from "component-library";

export interface IStatusPillProps {
  isActive: boolean;
  isRenewing: boolean;
  isCanceled: boolean;
}

export const getStatusPillClassNames = (
  isActive: boolean,
  isRenewing: boolean,
  isCanceled: boolean
) =>
  cx("self-start md:self-auto", {
    "bg-color-accent-one text-color-neutral-one": isActive,
    "bg-orange-burnt text-white": isRenewing || isCanceled,
    "bg-grey-light-2": !isActive && !isCanceled,
  });

const StatusPill = ({ isActive, isRenewing, isCanceled }: IStatusPillProps) => {
  const { t } = useTranslation();

  const getLabel = () => {
    if (isRenewing || isCanceled) return t("common:label.renewing");

    if (isActive) return t("common:label.active");

    return t("common:label.inactive");
  };

  return (
    <Pill
      size="md"
      className={getStatusPillClassNames(isActive, isRenewing, isCanceled)}
    >
      {getLabel()}
    </Pill>
  );
};

export default StatusPill;
