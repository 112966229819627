import React from "react";
import { useDispatch, useSelector } from "react-redux";
import cloneDeep from "lodash/cloneDeep";

import PageKey from "config/PageKey";
import { IApplicationState } from "store";
import { getPageTranslation } from "store/translation/translation.actions";
import { fallbackTranslation } from "services/api/fallbackData";

interface useTranslationParams {
  pageKey: PageKey;
}

/**
 * This hook returns the translations from Umbraco for a particular page
 */
export const useTranslation = <TTranslationType extends {}>({
  pageKey,
}: useTranslationParams) => {
  const dispatch = useDispatch();
  const { translation: translationInAppState, locale } = useSelector(
    (global: IApplicationState) => ({
      translation: global.translation.pages[pageKey] as
        | TTranslationType
        | undefined,
      locale: global.translation.pageLocale,
    })
  );
  const [translation, setTranslation] = React.useState<TTranslationType>();

  const loadTranslation = React.useCallback(() => {
    return dispatch(getPageTranslation({ locale, pageKey }));
  }, []);

  const combineDefaults = <TTranslationType extends {}>(
    translation: TTranslationType,
    fallback: TTranslationType
  ) => {
    Object.entries(translation).forEach(([key, value]) => {
      const translationValue = value;
      const fallbackValue = fallback[key];

      if (
        translationValue !== null &&
        translationValue instanceof Object &&
        !Array.isArray(translationValue)
      ) {
        combineDefaults(value, fallback[key]);

        return;
      }

      translation[key] = translationValue ?? fallbackValue;
    });
  };

  const combineFallbackData = <TTranslationType extends {}>(
    translation: TTranslationType,
    fallback: TTranslationType
  ) => {
    const originalCopy = cloneDeep(translation);
    combineDefaults(originalCopy, fallback);

    return cloneDeep(originalCopy);
  };

  React.useEffect(() => {
    if (!translationInAppState) {
      loadTranslation();
    }
  }, []);

  React.useEffect(() => {
    if (translationInAppState) {
      // prepare translation - falling back on any values not received
      const fallbackTranslations = fallbackTranslation[pageKey];
      const combinedTranslationData = combineFallbackData<TTranslationType>(
        translationInAppState,
        fallbackTranslations
      );
      setTranslation(combinedTranslationData);
    }
  }, [translationInAppState]);

  return { loadingTranslation: !translation, translation, locale };
};
