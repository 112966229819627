import React, { FC } from "react";
import { useTranslation } from "i18n";

import { IconTextLink, Pill } from "component-library";
import { dataTestIds } from "data-testids";
import {
  AddOnCodes,
  addOnMoreInfoLinkMap,
  addOnNamesMap,
} from "component-library/src/components/Organisms/Billing/constants";
import { ReactComponent as CrossIcon } from "component-library/src/images/svg/icons/cross-thin.svg";
import { AddOnCalculatedStatus, buyAddOnButtonNamesMap } from "app/constants";
import { IAddOn } from "services/api/BillingApi/BillingApi";
import { getStatusPillClassNames } from "components/StatusPill/StatusPill";
import SectionBox from "components/SectionBox";

interface IAddOnSectionProps {
  addOn: IAddOn;
  isSubscriptionActive: boolean;
  onCancelAddOnClick: () => void;
  onBuyAddOnClick: (code: AddOnCodes) => void;
}

const AddOnSection: FC<IAddOnSectionProps> = ({
  addOn,
  isSubscriptionActive,
  onCancelAddOnClick,
  onBuyAddOnClick,
}) => {
  const { t } = useTranslation();
  const { description, calculatedStatus, availableToBuy, code, cancelled } =
    addOn;
  const isActive = calculatedStatus === AddOnCalculatedStatus.Active;
  const isFilmMakersAddOn =
    code === AddOnCodes.FilmMakersAnnual ||
    code === AddOnCodes.FilmMakersMonthly;
  const showCancelButton = isActive && isFilmMakersAddOn && !cancelled;
  const isAvailable =
    !showCancelButton && !cancelled && !isActive && availableToBuy;

  return (
    <SectionBox
      title={t(addOnNamesMap[code])}
      description={description}
      bgColour="bg-color-neutral-three"
      pill={
        <Pill
          size="md"
          className={getStatusPillClassNames(isActive, false, false)}
          theme="light"
        >
          {isActive ? t("common:label.active") : t("common:label.inactive")}
        </Pill>
      }
      primaryButton={
        isAvailable && (
          <IconTextLink
            text={t(buyAddOnButtonNamesMap[code])}
            variant="button-icon-text-secondary"
            svgIconStart={
              <CrossIcon className="rotate-45" height={20} width={20} />
            }
            data-testid={
              dataTestIds.userAccountApp["MySubscription.BuyAddOn.button"]
            }
            handleClick={() => onBuyAddOnClick(code)}
          />
        )
      }
      secondaryButton={
        showCancelButton && (
          <IconTextLink
            text={t("common:button.label.cancelAddOn")}
            variant="button-icon-text"
            svgIconStart={<CrossIcon />}
            handleClick={onCancelAddOnClick}
            className="text-red-cardinal"
          />
        )
      }
      link={addOnMoreInfoLinkMap[addOn.code]}
    />
  );
};

export default AddOnSection;
