import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { useQuery } from "react-query";
import { useTranslation } from "i18n";
import { authorizationService, checkIsFilmmakersProfile } from "shared-auth";
import PageKey from "config/PageKey";

import { IApplicationState } from "store";
import { getMyAccountPage } from "store/page/page.actions";
import { GetMyAccountPage } from "store/page/page.types";
import { getPageTranslation } from "store/translation/translation.actions";
import { GetPageTranslation } from "store/translation/translation.types";
import { hidePopup, setLoading, showPopup } from "store/ui/ui.actions";
import { HidePopup, SetLoading, ShowPopup } from "store/ui/ui.types";

import { MapDispatchToProps } from "types/utilities";

import withPageData, {
  ILoaders,
  IWithPageDataProps,
  mapStateToWithPageDataProps,
} from "hoc/withPageData";
import { FEATURE_FLAGS, useLaunchDarklyFlags } from "shared-services";

import { AccountType, Button, VideoPlayer } from "component-library";
import { IVideoModel, MemberGroups, AddOnEntitlements } from "shared-types";
import { dataTestIds } from "data-testids";
import {
  HtmlAnchor,
  MediaPickerDataTypes,
  MediaPickerVideo,
} from "models/translation";
import ResponsiveImage from "components/ResponsiveImage/ResponsiveImage";
import { IMyAccountPageStore } from "models/page";
import {
  getAccountStatusNotificationForUser,
  getMarketingMessageForUser,
  getQuickLinksForUser,
  getServiceBannerForUser,
  IQuickLink,
  isAccountStatusActive,
} from "./MyAccountPageUserMessaging";
import { pushToDataLayer } from "app-shell/GoogleTagManager/GoogleTagManager";
import PerformerSearch from "components/SearchBox/PerformerSearch";
import UserAccountService from "services/api/UserAccount/UserAccountService";
import Layout from "app-shell/Layout/Layout";
import {
  SubscriptionNotificationBanner,
  FilmmakersTrialBanner,
  CdStudiosBanner,
} from "./NotificationBanner";

import {
  IBillingApiResponse,
  getRecurlyDetails,
} from "services/api/BillingApi/BillingApi";

import "./MyAccountPage.scss";

export interface IMyAccountPageActions {
  getPageTranslation: GetPageTranslation;
  setLoading: SetLoading;
  showPopup: ShowPopup;
  hidePopup: HidePopup;
  getMyAccountPage: GetMyAccountPage;
}

export interface IMyAccountPageProps
  extends IMyAccountPageActions,
    IMyAccountPageStore,
    IWithPageDataProps {}

interface IUserMessages {
  serviceBannerHeading: string | undefined;
  serviceBannerMessage: string | undefined;
  serviceBannerMedia: MediaPickerDataTypes | undefined | null;
  quickLinks: IQuickLink[];
  marketingBannerHeading: string | undefined;
  marketingBannerBody: string | undefined;
  marketingBannerCallToActionUrl: string | undefined;
  marketingBannerBackgroundImage: MediaPickerDataTypes | undefined | null;
  marketingBannerCallToActionText: string | undefined;
  accountStatusNotificationMessage: string | undefined;
  accountStatusNotificationButtons: ReadonlyArray<HtmlAnchor>;
}

function mediaIsVideo(media: MediaPickerDataTypes): media is MediaPickerVideo {
  return (media as MediaPickerVideo).durationInMilliseconds != undefined;
}

const getMediaElement = (media: MediaPickerDataTypes | null | undefined) => {
  if (!media) {
    return null;
  }

  if (mediaIsVideo(media)) {
    const video: IVideoModel = {
      duration: media.durationInMilliseconds,
      fileName: "",
      sources: [media.url || ""],
      id: "notrelevantvideoid",
      previewImg: media.previewImageUrl || "",
      poster: media.previewImageUrl || "",
      downloadLink: "",
      shareLink: "",
    };

    return (
      <div className="c-my-account-page__video-container">
        <div className="c-my-account-page__video-container-inner">
          <VideoPlayer
            autoPlay={false}
            hasCloseButton={false}
            hasDownloadButton={false}
            selectedVideo={video}
            videoList={[video]}
            selectVideo={() => {}}
          />
        </div>
      </div>
    );
  }

  return <ResponsiveImage src={media.url} />;
};

const handleDiscountsLink = async () => {
  const discountUrl = await UserAccountService.redirectToDiscountPage();

  if (discountUrl) {
    window.location.href = discountUrl;
  }
};

const emptyHtmlAnchorList: HtmlAnchor[] = [];

export const MyAccountPage = (props: IMyAccountPageProps) => {
  const { t } = useTranslation();
  const isFilmmakersTrialFlagEnabled = useLaunchDarklyFlags(
    FEATURE_FLAGS.FilmmakersTrial
  );
  const isNewJoiningCDProcess = useLaunchDarklyFlags(
    FEATURE_FLAGS.JoiningCDProcess
  );
  const isMainOrGradPerformer = [
    MemberGroups.performer,
    MemberGroups.graduatePerformer,
  ].includes(props.userInfo?.[0].memberGroup as MemberGroups);

  const isYP = props.userInfo?.[0].memberSubType === "youngPerformer";
  const isCD =
    authorizationService.getUserGroup() === MemberGroups.castingDirector;

  const {
    data: performerSubscriptionData,
    isLoading: isPerformerSubscriptionDataLoading,
  } = useQuery<IBillingApiResponse | undefined>(
    "account-settings",
    getRecurlyDetails,
    {
      enabled: isMainOrGradPerformer,
    }
  );

  useEffect(() => {
    const userInfo = props.userInfo?.[0];
    pushToDataLayer({ pageName: "myhome", accountType: userInfo?.memberGroup });
  }, []);

  const getUserMessages = (): IUserMessages | undefined => {
    const useRecurlyFlag = isNewJoiningCDProcess && isCD;
    const pageTranslation = props.translation.pages[PageKey.MyAccount];

    if (!pageTranslation) {
      return undefined;
    }

    let userInfo = props.userInfo?.[0];

    if (!userInfo) {
      return undefined;
    }

    userInfo = { ...userInfo, managedByRecurly: useRecurlyFlag };

    const notificationBanner = getAccountStatusNotificationForUser(
      userInfo,
      props.hasPaidRecently ?? false,
      pageTranslation,
      performerSubscriptionData
    );

    return {
      ...getServiceBannerForUser(userInfo, pageTranslation),
      quickLinks: getQuickLinksForUser(userInfo) || emptyHtmlAnchorList,
      ...getMarketingMessageForUser(userInfo, pageTranslation),
      ...notificationBanner,
    };
  };

  const isFilmmakersProfile = checkIsFilmmakersProfile();

  const hasDiscountQuickLink =
    props.user.type == AccountType.Performer && !isFilmmakersProfile;

  const isActiveCastingDirector = () => {
    if (!props.userInfo) {
      return false;
    }

    return (
      props.userInfo.filter(
        (x) =>
          isAccountStatusActive(x.membershipStatus) &&
          x.memberGroup == MemberGroups.castingDirector
      ).length > 0
    );
  };

  const fullName = props.user?.username || "";
  const showPerformerSearch = isActiveCastingDirector();
  const userHasFilmmakersEntitlement =
    authorizationService.doesUserHaveEntitlements(
      AddOnEntitlements.FilmmakersSync
    );

  const shouldShowFMTrialBanner =
    !userHasFilmmakersEntitlement &&
    isMainOrGradPerformer &&
    !isYP &&
    isFilmmakersTrialFlagEnabled;
  const pageTranslation = props.translation.pages[PageKey.MyAccount];
  const userMessages = getUserMessages();

  return (
    <Layout>
      <section className="c-my-account-page">
        <div className="c-my-account-page__hero-area">
          <div className="g-content-area c-my-account-page__hero-area-content">
            <div className="mt-[10px] g-col-lg-12">
              <h1>
                {pageTranslation?.common?.welcomeBackFormat?.replace(
                  "{userFullName}",
                  fullName.toLocaleUpperCase()
                )}
              </h1>
            </div>
          </div>
        </div>
        {isCD && <CdStudiosBanner />}
        {shouldShowFMTrialBanner && (
          <FilmmakersTrialBanner
            isLoading={isPerformerSubscriptionDataLoading}
            performerSubscriptionData={performerSubscriptionData}
          />
        )}
        {userMessages?.accountStatusNotificationMessage &&
          userMessages?.accountStatusNotificationButtons && (
            <SubscriptionNotificationBanner
              performerSubscriptionData={performerSubscriptionData}
              isLoading={isPerformerSubscriptionDataLoading}
              messageHtml={userMessages?.accountStatusNotificationMessage}
              buttons={userMessages.accountStatusNotificationButtons}
            />
          )}
        {userMessages && (
          <div className="c-my-account-page__main-area">
            <div className="g-content-area">
              <div className="g-col-lg-8 g-bottom-spacing">
                <div className="g-inner-grid-2 c-my-account-page__service-message">
                  <div className="g-col-lg-1">
                    <h2>{userMessages?.serviceBannerHeading}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: userMessages?.serviceBannerMessage || "",
                      }}
                    />
                  </div>
                  <div className="g-col-lg-1">
                    {getMediaElement(userMessages?.serviceBannerMedia)}
                  </div>
                </div>
              </div>
              <div className="g-col-lg-4 g-bottom-spacing">
                {showPerformerSearch && (
                  <div className="c-my-account-page__search-container">
                    <PerformerSearch />
                  </div>
                )}
                <div className="c-my-account-page__quick-links">
                  <h2
                    data-testid={
                      dataTestIds.userAccountApp[
                        "MyAccountPage.QuickLinks.header"
                      ]
                    }
                  >
                    {pageTranslation?.common?.quickLinksHeading}
                  </h2>
                  {userMessages?.quickLinks.length > 0 && (
                    <ul>
                      {userMessages?.quickLinks.map((x, index) => (
                        <li key={index}>
                          <Button
                            type="tertiary"
                            text={t(x.text)}
                            href={x.href}
                            size="medium"
                            data-testid={
                              dataTestIds.userAccountApp[
                                "MyAccountPage.QuickLinks.link"
                              ]
                            }
                          />
                        </li>
                      ))}
                      {hasDiscountQuickLink && (
                        <li>
                          <Button
                            type="tertiary"
                            text={t("common:billing.planFeature.discounts")}
                            onClick={handleDiscountsLink}
                            size="medium"
                            data-testid={
                              dataTestIds.userAccountApp[
                                "MyAccountPage.QuickLinks.link"
                              ]
                            }
                          />
                        </li>
                      )}
                    </ul>
                  )}
                </div>
              </div>
              {userMessages?.marketingBannerBody && (
                <ResponsiveImage
                  className="g-col-lg-12 g-inner-grid-12 c-my-account-page__marketing-message"
                  src={userMessages?.marketingBannerBackgroundImage}
                  asBackgroundImage
                  backgroundPosition="center"
                >
                  <div className="g-col-lg-7 c-my-account-page__marketing-message-inner">
                    <h2>{userMessages?.marketingBannerHeading}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: userMessages?.marketingBannerBody || "",
                      }}
                    />
                  </div>
                  <p className="c-my-account-page__marketing-cta">
                    <a href={userMessages?.marketingBannerCallToActionUrl}>
                      {userMessages?.marketingBannerCallToActionText}
                      <span className="c-my-account-page__chevron-button icon-chevronright" />
                    </a>
                  </p>
                </ResponsiveImage>
              )}
            </div>
          </div>
        )}
      </section>
    </Layout>
  );
};

const pageDataLoaders: ILoaders<IMyAccountPageProps> = {
  loadTranslation(props, isLocaleChanged) {
    return props.getPageTranslation(
      { pageKey: PageKey.MyAccount, locale: props.locale },
      { throwOnError: isLocaleChanged }
    );
  },
  loadData(props) {
    return props.getMyAccountPage();
  },
};

const mapStateToProps = (state: IApplicationState): IMyAccountPageStore => ({
  userInfo: state.page.data?.userInfo,
  hasPaidRecently: state.page.data?.hasPaidRecently,
});

const mapDispatchToProps: MapDispatchToProps<IMyAccountPageActions> = {
  getPageTranslation,
  setLoading,
  showPopup,
  hidePopup,
  getMyAccountPage,
};

export default compose(
  connect(mapStateToWithPageDataProps, mapDispatchToProps),
  withPageData<IMyAccountPageProps>(PageKey.MyAccount, pageDataLoaders),
  connect(mapStateToProps, mapDispatchToProps)
)(MyAccountPage) as React.ComponentType;
