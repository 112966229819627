import { Reducer } from "redux";

import { ActionType } from "store/types";

import { IUserState, UserActionTypes } from "./user.types";

export const initialState: IUserState = {
  isLoaded: false,
  data: undefined,
};

const reducer: Reducer<IUserState, UserActionTypes> = (
  state: IUserState = initialState,
  action: UserActionTypes
) => {
  switch (action.type) {
    case ActionType.SetUserData:
      // eslint-disable-next-line no-case-declarations
      const {
        payload: {
          id,
          username,
          type,
          memberGroups,
          email,
          forenames,
          surname,
        },
      } = action;

      return {
        ...state,
        isLoaded: true,
        data: {
          id,
          username,
          type,
          memberGroups: memberGroups,
          email,
          forenames,
          surname,
        },
      };
    case ActionType.SetUserType: {
      return {
        ...state,
        data: {
          ...state.data!,
          type: action.payload.type,
          memberGroups: action.payload.memberGroups,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
