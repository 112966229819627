import {
  AddOnCheckout,
  SubscriptionPage,
} from "component-library/src/components/Organisms/Billing";
import { useState } from "react";

export default function useAddOnCheckoutSteps() {
  const [addOnCheckoutStep, setAddOnCheckoutStep] = useState(0);
  const stepMap: { [key: number]: any } = {
    0: SubscriptionPage,
    1: AddOnCheckout,
  };

  return {
    Component: stepMap[addOnCheckoutStep],
    setAddOnCheckoutStep,
    addOnCheckoutStep,
  };
}
