import React, { FC } from "react";
import Layout from "app-shell/Layout/Layout";
import {
  SectionContainer,
  ISectionContainerProps,
  Button,
  BreadcrumbBar,
  IBreadcrumbBarItem,
} from "component-library";
import { dataTestIds } from "data-testids";

interface PageWrapperWithinLayoutProps extends ISectionContainerProps {
  pageHeader: string;
  subFooter?: {
    text: string;
    onClick: () => void;
  };
  breadcrumbs: IBreadcrumbBarItem[];
}

const PageWrapperWithinLayout: FC<PageWrapperWithinLayoutProps> = ({
  pageHeader,
  subFooter,
  children,
  breadcrumbs,
}) => {
  const containerProps: ISectionContainerProps = {
    variant: "shadowed",
  };

  return (
    <Layout contentContainerCustomClassName="bg-color-neutral-three flex flex-col flex-1">
      <BreadcrumbBar items={breadcrumbs} />
      <h1 className="w-full m-auto max-w-[1440px] px-4 mt-3 text-3xl">
        {pageHeader}
      </h1>
      <div className="w-full m-auto flex-1 md:px-4 py-5  max-w-[1440px]">
        <div className="g-col-lg-12">
          <SectionContainer {...containerProps}>
            <div className="relative px-4 md:px-4">{children}</div>
          </SectionContainer>
        </div>
      </div>
      {subFooter && (
        <div
          className="flex justify-center md:justify-end high-[50px] px-[15px] py-[5px] sticky
          bottom-0 bg-white rounded-t-lg shadow-[0px_-8px_10px_0px_#1c1c1c33]"
        >
          <Button
            type="secondary"
            className="max-w-[330px]"
            text={subFooter.text}
            onClick={subFooter.onClick}
            data-testid={
              dataTestIds.userAccountApp["Pagewrapper.Footer.closeButton"]
            }
          />
        </div>
      )}
    </Layout>
  );
};

export default PageWrapperWithinLayout;
