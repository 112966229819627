import { AccountType } from "component-library";
import config from "config/global";
import { BillingUser } from "translations/src/models/billing";

export const PROJECT_API_NAME = "useraccountapi";

export const SCROLLING_CONTAINER_ID = "scrollingContainer";

export const DEFAULT_LOCALE = "en-GB";

export const BASE_PATH = "/myhome"; // TODO replace with base url

export enum ErrorCode {
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  UnprocessableEntity = 422,
  InternalServerError = 500,
}

export const ACCOUNT_TYPE_GTM_MAPPING = {
  [AccountType.Agent]: config.gtm.accountTypes.agent,
  [AccountType.Performer]: config.gtm.accountTypes.performer,
  [AccountType.CastingDirector]: config.gtm.accountTypes.castingDirector,
};

export enum ErrorPopupType {
  System = "systemError",
  Account = "accountError",
  Access = "accountAccessError",
  Network = "networkError",
}

export const AccountTypeBillingTypeMap = {
  castingDirector: BillingUser.Casting,
  "castingDirector:searchOnlyCastingDirector": BillingUser.Casting,
  performer: BillingUser.Performer,
  "performer:youngPerformer": BillingUser.YoungPerformer,
  "performer:graduate": BillingUser.Graduate,
  // Transferring grads are main perfs; handled in useGetUserType
};
