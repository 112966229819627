import React, { FC, ReactNode } from "react";
import DOMPurify from "dompurify";
import cx from "classnames";
import { useTranslation } from "i18n";

import { ReactComponent as OpenInNewTabIcon } from "component-library/src/images/svg/icons/open-in-new-tab.svg";

interface ISectionBoxProps {
  title: string;
  description?: string;
  bgColour: string;
  pill?: ReactNode;
  primaryButton?: ReactNode;
  secondaryButton?: ReactNode;
  link?: string;
  icon?: ReactNode;
}

const SectionBox: FC<ISectionBoxProps> = ({
  title,
  description,
  bgColour,
  pill,
  primaryButton,
  secondaryButton,
  link,
  icon,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cx(
        "flex flex-1 flex-row gap-2 p-3 w-full rounded relative md:flex-none",
        bgColour,
        {
          "flex-row": icon || link,
          "flex-col": !icon && !link,
        }
      )}
    >
      {icon && <div>{icon}</div>}
      <div className="flex flex-col gap-3">
        <div className="flex flex-col-reverse gap-3 md:flex-row md:items-center md:gap-4 md:">
          <h6 className="font-semibold">{title}</h6>
          {pill}
        </div>
        {description && (
          <p
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(description),
            }}
          />
        )}
        {(primaryButton || secondaryButton) && (
          <div className="flex flex-col items-start gap-2 md:flex-row">
            {primaryButton}
            {secondaryButton}
          </div>
        )}
      </div>
      {link && (
        <div className="absolute flex items-center self-start flex-1 gap-1 right-2 md:static whitespace-nowrap">
          <a
            href={link}
            target="_blank"
            className="text-sm font-semibold leading-snug md:text-base"
            rel="noreferrer"
          >
            {t("common:label.moreInformation")}
          </a>
          <OpenInNewTabIcon className="text-color-brand-one" />
        </div>
      )}
    </div>
  );
};

export default SectionBox;
