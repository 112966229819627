export enum FormFields {
  Email = "email",
  Password = "password",
  ConfirmPassword = "confirmPassword",
  SecurityQuestion = "question",
  SecurityAnswer = "answer",
}

export const USER_DETAILS_QUERY_KEY = "userDetails";

export const SECURITY_QUESTION_OPTIONS_QUERY_KEY = "securityQuestionOptions";
