import React, { FC, ReactNode } from "react";
import cx from "classnames";
import DOMPurify from "dompurify";
import { Button } from "component-library";
import { HtmlAnchorButton } from "pages/IndexPage/MyAccountPageUserMessaging";
import { dataTestIds } from "data-testids";

type NotificationBannerProps = {
  messageHtml: string;
  buttons: ReadonlyArray<HtmlAnchorButton>;
  wrapperClassNames?: string;
  icon?: ReactNode;
};

const NotificationBanner: FC<NotificationBannerProps> = ({
  buttons,
  children,
  wrapperClassNames,
  messageHtml,
  icon,
}) => {
  const messageColumns = 12 - buttons.length * 3;

  return (
    <div
      className={cx("c-my-account-page__notification-area", wrapperClassNames)}
    >
      <div className="flex flex-col g-content-area">
        <div
          className={`g-col-lg-${messageColumns} c-my-account-page__notification-area__container`}
        >
          {icon}
          <div
            className="c-my-account-page__notification-area__message"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(messageHtml),
            }}
          />
        </div>
        {children}
        {buttons.length > 0 ? (
          <div className="flex flex-col items-stretch md:gap-2 md:flex-row md:justify-end">
            {buttons.map((b) => (
              <div
                key={b.href}
                className="g-col-lg-3 c-my-account-page__notification-area__button self-stretch md:min-w-[200px]"
              >
                <Button
                  type={b.type || "primary"}
                  newTab={b.target === "_blank"}
                  text={b.text}
                  onClick={b.onClick}
                  href={b.href}
                  size="medium"
                  className={b.className}
                  data-testid={
                    dataTestIds.userAccountApp[
                      "MyAccountPage.NotificationBanner.button"
                    ] + (b.type || "primary")
                  }
                />
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default NotificationBanner;
