import React from "react";
import { getErrorPopupTexts } from "component-library";
import { GetCommonTranslation } from "store/translation/translation.types";
import { HidePopup, SetLoading } from "store/ui/ui.types";

import getErrorPopupType from "helpers/getErrorPopupType";

import ErrorPopup from "components/Popups/ErrorPopup/ErrorPopup";
import { IUserType } from "models/user";

export interface IWithLocalizedDataProps {
  isCommonTranslationLoaded: boolean;
  userType: IUserType | undefined;
  locale: string;
  setLoading: SetLoading;
  hidePopup: HidePopup;
  getCommonTranslation: GetCommonTranslation;
}

interface IWithLocalizedDataState {
  showError: boolean;
}

function userTypeIsEqual(x: IUserType | undefined, y: IUserType | undefined) {
  if (x == y) {
    return true;
  }

  if (x == undefined || y == undefined) {
    return false;
  }

  const groupX = x.memberGroups?.[0];
  const groupY = y.memberGroups?.[0];

  if (groupX && groupY) {
    return groupX.group == groupY.group && groupX.subType == groupY.subType;
  }

  return groupX == groupY;
}

export default function withLocalizedData(
  WrappedComponent: React.ComponentType<any>
): typeof React.Component {
  return class extends React.Component<
    IWithLocalizedDataProps,
    IWithLocalizedDataState
  > {
    public readonly state: IWithLocalizedDataState = { showError: false };

    public componentDidMount(): void {
      if (this.props.userType) {
        this.loadData();
      }
    }

    public componentDidUpdate(prevProps: IWithLocalizedDataProps): void {
      if (this.props.locale !== prevProps.locale) {
        // User has probably changed the locale, so pass true so that an error appears
        this.loadData(true);
      } else if (!userTypeIsEqual(this.props.userType, prevProps.userType)) {
        // The user has been loaded into the app, just use fallback data
        this.loadData(false);
      }
    }

    public handleClick = () => {
      location.reload();
    };

    public render(): React.ReactNode | null {
      if (this.state.showError) {
        return this.renderErrorPopup();
      }

      return <WrappedComponent {...this.props} />;
    }

    public renderErrorPopup() {
      return (
        <ErrorPopup
          {...getErrorPopupTexts()[getErrorPopupType()]}
          handleButtonClick={this.handleClick}
          handleCloseClick={this.handleClick}
          hidePopup={this.props.hidePopup}
        />
      );
    }

    private async loadData(throwOnError: boolean = false) {
      if (this.props.userType) {
        try {
          await Promise.all([
            this.props.getCommonTranslation(
              { locale: this.props.locale },
              { throwOnError }
            ),
          ]);
        } catch (e) {
          console.error(e);
          this.props.setLoading(false);
          this.setState({
            showError: true,
          });
        }
      }
    }
  };
}
