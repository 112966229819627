import React, { FC } from "react";

interface IBannerMessageProps {
  title: string;
  description: React.JSX.Element | string;
}

const BannerMessage: FC<IBannerMessageProps> = ({
  title,
  description,
}: IBannerMessageProps) => {
  return (
    <div className="flex flex-col flex-1 w-full p-3 border bg-color-neutral-three border-grey-fog-1">
      <h6 className="text-[14px] font-bold">{title}</h6>
      <p className="text-[14px]">{description}</p>
    </div>
  );
};

export default BannerMessage;
