import { DATE_FORMAT } from "app/constants";
import { format, toDate, utcToZonedTime } from "date-fns-tz";

const BROWSER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export default function getLongDate(date: Date) {
  const zonedDate = date
    ? utcToZonedTime(
        toDate(`${date}`, {
          timeZone: BROWSER_TIMEZONE,
        }),
        BROWSER_TIMEZONE
      )
    : "";

  const formattedDate = format(zonedDate as Date, DATE_FORMAT, {
    timeZone: BROWSER_TIMEZONE,
  });

  return formattedDate;
}
