import React, { FC } from "react";
import { useReturnUrl } from "shared-hooks";
import { useHistory } from "react-router-dom";
import { useTranslation } from "i18n";
import cx from "classnames";

import { HtmlAnchorButton } from "pages/IndexPage/MyAccountPageUserMessaging";
import { IBillingApiResponse } from "services/api/BillingApi/BillingApi";
import checkIsRenewalPeriod from "helpers/checkIsRenewalPeriod";
import checkIsNoActiveSubscription from "helpers/checkIsNoActiveSubscription";
import applicationMap from "services/applicationMap";
import checkIsValidAddress from "helpers/checkIsValidAddress";
import { getCheckoutLink } from "helpers/getCheckoutLink";
import { useUpdateDetailsPopUp } from "app/hooks/useUpdateDetailsPopUp";
import NotificationBanner from "./NotificationBanner";
import { ReactComponent as InfoIconSvg } from "component-library/src/images/svg/icons/warning-icon-base.svg";
import { SubscriptionCalculatedStatus, SubscriptionState } from "app/constants";
import getLongDate from "helpers/getLongDate";
import { REINSTATE_SEARCH_PARAM } from "../constants/constants";

type NotificationBannerProps = {
  messageHtml: string;
  buttons: ReadonlyArray<HtmlAnchorButton>;
  performerSubscriptionData: IBillingApiResponse | undefined;
  isLoading: boolean;
};

const SubscriptionNotificationBanner: FC<NotificationBannerProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { redirectWithReturnUrl } = useReturnUrl();

  const { address, account, subscription } =
    props.performerSubscriptionData || {};

  const isExpired =
    subscription?.calculatedStatus === SubscriptionCalculatedStatus.Expired;

  const reactivationLink = getCheckoutLink({
    planCode: subscription?.planCode,
    isReactivation: true,
  });
  const renewalLink = getCheckoutLink({
    planCode: isExpired ? undefined : subscription?.planCode,
  });
  const targetLink = isExpired ? reactivationLink : renewalLink;
  const noActiveSubscription = checkIsNoActiveSubscription({
    id: account?.id,
    subscription: subscription,
  });
  const isRenewalPeriod = checkIsRenewalPeriod({
    planCode: subscription?.planCode,
    calculatedStatus: subscription?.calculatedStatus,
  });
  const hasCanceledSubscription =
    subscription?.state === SubscriptionState.Canceled;

  const isValidAddress = checkIsValidAddress(address);

  const updateMyDetailRedirect =
    !isValidAddress && (noActiveSubscription || isRenewalPeriod);

  const checkoutPageRedirect = isValidAddress && isRenewalPeriod;

  const handleRedirectUser = () => {
    if (updateMyDetailRedirect) {
      redirectWithReturnUrl(
        applicationMap.routes.settingsMyDetails(),
        targetLink
      );

      return;
    }

    if (checkoutPageRedirect) {
      history.push(renewalLink);
    }
  };

  const { showUpdateDetailsPopUp } = useUpdateDetailsPopUp(handleRedirectUser);

  const handleReactivationRenewClick = () => {
    if (!isValidAddress) {
      return showUpdateDetailsPopUp();
    } else {
      return handleRedirectUser();
    }
  };

  let buttons = props.buttons;
  let messageHtml = props.messageHtml;

  if (hasCanceledSubscription) {
    buttons = [
      {
        title: t("common:button.label.reinstate"),
        text: t("common:button.label.reinstate"),
        href:
          applicationMap.routes.settingsMySubscription() +
          `?${REINSTATE_SEARCH_PARAM}=true`,
        className: "bg-red-cardinal border-red-cardinal",
      },
    ];
    messageHtml = t("common:banner.reinstate.messageHtml", {
      date: getLongDate(subscription?.currentTermEndsAt),
    });
  } else if (isRenewalPeriod) {
    buttons = [
      {
        ...props.buttons[0],
        onClick: handleReactivationRenewClick,
      },
    ];
  } else if (isExpired) {
    buttons = [
      props.buttons[0],
      {
        ...props.buttons[1],
        onClick: handleReactivationRenewClick,
      },
    ];
  }

  if (props.isLoading) return null;

  return (
    <NotificationBanner
      messageHtml={messageHtml}
      buttons={buttons}
      icon={
        <InfoIconSvg
          width={24}
          height={24}
          className={cx("mr-2 text-color-brand-one", {
            "text-red-cardinal": hasCanceledSubscription,
          })}
        />
      }
    />
  );
};

export default SubscriptionNotificationBanner;
