import React from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { Spinner } from "component-library";
import {
  BillingContextProvider,
  IAddOns,
} from "component-library/src/components/Organisms/Billing";
import {
  CheckoutAccessTypes,
  PlanCodePeriod,
  PlanSelectorSearchParams,
} from "component-library/src/components/Organisms/Billing/types";
import { renewSubscriptionWithExisting } from "services/api/BillingApi/BillingApi";
import applicationMap from "services/applicationMap";
import { pushToDataLayer } from "app-shell/GoogleTagManager/GoogleTagManager";
import { TRACKING_EVENT_TYPE } from "app-shell/GoogleTagManager/constants";
import useAddOnCheckoutSteps from "../hooks/useAddOnCheckoutPages";
import { useGetBillingContextDefaultProps } from "../hooks/useGetBillingContextDefaultProps";
import { logCustomEvent as brazeCustomEvent } from "@braze/web-sdk";
import { CheckoutBrazeEventTypes } from "pages/CheckoutPageWrapper/types";

interface Props extends CheckoutBrazeEventTypes {}

export default function PerformerWrapper({
  brazeSubscriptionPurchaseType,
  brazeAddOnPurchaseType,
}: Props) {
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const preselectedPlan = urlParams.get(
    PlanSelectorSearchParams.PreSelectedPlan
  );
  const { Component, setAddOnCheckoutStep, addOnCheckoutStep } =
    useAddOnCheckoutSteps();
  const defaultProps = useGetBillingContextDefaultProps(
    CheckoutAccessTypes.RenewalReactivation
  );

  const { mutate: renewalMutation, isLoading: isRenewalLoading } = useMutation(
    renewSubscriptionWithExisting,
    {
      // TODO: Figure out a way to dynamically set the isDirectDebit and use handleNavigateToCompletePage()
      onSuccess: () => history.push(applicationMap.routes.accountSettings()),
    }
  );

  const postCheckoutCallback = (
    data: any,
    trackingData: Record<string, any>,
    isUsingExistingPaymentMethod?: boolean
  ) => {
    const { planId, couponCodes } = data;

    if (isUsingExistingPaymentMethod) {
      renewalMutation({ planId, couponCodes });
    }

    pushToDataLayer({
      event: TRACKING_EVENT_TYPE.ReactivateRenew,
      memberTier: data?.planTypeFilter,
      ...trackingData,
    });

    brazeCustomEvent(brazeSubscriptionPurchaseType, {
      memberType: trackingData.memberType,
      paymentPeriod: trackingData.paymentPeriod,
      revenue: trackingData.paymentValue,
    });

    trackingData?.addOnData?.forEach((addOn: IAddOns) => {
      brazeCustomEvent(brazeAddOnPurchaseType, {
        memberType: trackingData.memberType,
        paymentPeriod: addOn.code,
        revenue: addOn.amount,
      });
    });
  };

  const handleGoBack = () => {
    if (addOnCheckoutStep) return setAddOnCheckoutStep(0);

    history.push(applicationMap.routes.index());
  };

  const handleGoNext = () => {
    if (!addOnCheckoutStep) setAddOnCheckoutStep(1);
  };

  if (isRenewalLoading) return <Spinner />;

  return (
    <BillingContextProvider
      {...defaultProps}
      postCheckoutCallback={postCheckoutCallback}
      goBack={handleGoBack}
      goNext={handleGoNext}
      preselectedPlanOverride={preselectedPlan as PlanCodePeriod}
    >
      <Component />
    </BillingContextProvider>
  );
}
