import React from "react";
import { connect } from "react-redux";
import { getPage404Texts, Page404 } from "component-library";

import { setLoading } from "store/ui/ui.actions";
import { SetLoading } from "store/ui/ui.types";

export interface IPage404ContainerActions {
  setLoading: SetLoading;
}

export const Page404Container: React.FC<IPage404ContainerActions> = (props) => {
  React.useEffect(() => {
    props.setLoading(false);
  }, []);

  return <Page404 texts={getPage404Texts()} homePageLink="/" />;
};

const mapDispatchToProps = {
  setLoading,
};

export default connect(null, mapDispatchToProps)(Page404Container);
