import { TFunction } from "i18n";
import applicationMap from "services/applicationMap";

export const VALIDATE_SCHOOL_QUERY_KEY = "validateSchool";

export const SUBMIT_TRANSFER_QUERY_KEY = "submitTransfer";

export const GET_AGENTS_QUERY_KEY = "getAgents";

export const SELF_REPRESENTED_AGENT_REF = 50;

export enum FieldNames {
  SchoolPassword = "schoolPassword",
  Training = "training",
  CourseStartDate = "trainingFromYear",
  CourseEndDate = "trainingToYear",
  Agents = "agents",
}

export const graduatesTransferSteps = [
  applicationMap.routes.graduatesTransferInformation(),
  applicationMap.routes.checkout(),
  applicationMap.routes.checkout(),
];

export const getSchoolPasswordTooltip = (t: TFunction) => ({
  texts: {
    closeButton: "Close tooltip",
    tooltipName: "Open tooltip",
    content: t(
      "performer:graduateTransfer.information.training.tooltip.content"
    ),
  },
  config: {
    minimalTopDistance: 25,
  },
});

export const getGraduateStepTrackingItems = (t: TFunction) => [
  { label: t("performer:graduateTransfer.information.page.title") },
  { label: t("performer:graduateTransfer.subscription.page.title") },
  { label: t("performer:graduateTransfer.checkout.page.title") },
];

export const validateSchoolFormInitialValues = {
  [FieldNames.SchoolPassword]: "",
};

export type SubmitTransferFormValues = {
  [FieldNames.Training]: string;
  [FieldNames.CourseStartDate]: Date | null;
  [FieldNames.CourseEndDate]: Date | null;
  [FieldNames.Agents]: string[];
};

export const submitTransferFormInitialValues = {
  [FieldNames.Training]: "",
  [FieldNames.CourseStartDate]: null,
  [FieldNames.CourseEndDate]: null,
  [FieldNames.Agents]: [],
};
