import React, { useState } from "react";
import { useTranslation } from "i18n";
import NotificationBanner from "../NotificationBanner";
import { ReactComponent as InfoIconSvg } from "component-library/src/images/svg/icons/warning-icon-base.svg";

const CD_STUDIOS_BANNER_SHOWN = "cd-st-b-shown";
const hasCdUserInterractedWithBanner = JSON.parse(
  localStorage.getItem(CD_STUDIOS_BANNER_SHOWN) ?? "false"
);

const CdStudiosBanner = () => {
  const { t } = useTranslation();
  const [isBannerDismissed, setIsBannerDismissed] = useState(
    hasCdUserInterractedWithBanner
  );

  if (isBannerDismissed) {
    return null;
  }

  const handleDismiss = (): void => {
    localStorage.setItem(CD_STUDIOS_BANNER_SHOWN, "true");
    setIsBannerDismissed(true);
  };

  return (
    <NotificationBanner
      wrapperClassNames="bg-color-neutral-four"
      messageHtml={t("casting:home.banner.studios.message", {
        className: "c-my-account-page__notification-area__message_description",
      })}
      buttons={[
        {
          title: t("common:button.label.dismiss"),
          href: "##",
          text: t("common:button.label.dismiss"),
          type: "secondary",
          onClick: handleDismiss,
        },
        {
          title: t("common:button.label.findOutMore"),
          href: "https://www.spotlight.com/rooms-and-studios/studios",
          target: "_blank",
          text: t("common:button.label.findOutMore"),
        },
      ]}
      icon={
        <InfoIconSvg
          width={24}
          height={24}
          className="mr-2 text-color-brand-one"
        />
      }
    />
  );
};

export default CdStudiosBanner;
