import React from "react";
import memoize from "memoize-one";

import "./ResponsiveImage.scss";
import { MediaPickerDataTypes } from "models/translation";

interface IResponsiveImageCommonProps {
  src: string | MediaPickerDataTypes | undefined | null;
}
interface IResponsiveImageElementProps extends IResponsiveImageCommonProps {
  asBackgroundImage: false;
}

interface IResponsiveImageBackgroundProps extends IResponsiveImageCommonProps {
  className?: string;
  children?: React.JSX.Element[] | React.JSX.Element;
  asBackgroundImage?: true;
  backgroundPosition?: string;
}

interface IResponsiveImageState {
  lastWidth?: number;
}

const preferredWidths = [
  50, 100, 200, 320, 400, 640, 768, 800, 1024, 1280, 1366, 1600, 2048, 4096,
].sort((x, y) => y - x);

/**
 * Represents a responsive image. The image can either be an inline img element, or it can be a div that has the image src as a background
 * image. The size of the bitmap is resized by cloudinary based on the size of the element when it is rendered on the page.
 *
 * NOTE: In the future, we could attach resize events to it, so that the image is always the best quality for the size of the container
 */
export class ResponsiveImage extends React.Component<
  IResponsiveImageElementProps | IResponsiveImageBackgroundProps,
  IResponsiveImageState
> {
  private containerRef = React.createRef<HTMLDivElement>();
  private computeSrc = memoize(
    (src: string | undefined, containerWidth: number | undefined) => {
      if (src == undefined || containerWidth == undefined) {
        return undefined;
      }

      let preferredWidth = preferredWidths[0]; // default to the maximum
      for (const width of preferredWidths) {
        if (width >= containerWidth) {
          preferredWidth = width;
        }
      }

      return (
        "https://res.cloudinary.com/spotlightuk/image/fetch/w_" +
        preferredWidth +
        ",c_limit/" +
        src
      );
    }
  );
  public render() {
    const url =
      typeof this.props.src === "string" ? this.props.src : this.props.src?.url;

    const resizedSrc = this.computeSrc(url, this.state?.lastWidth);

    if (this.props.asBackgroundImage) {
      const backgroundImage = resizedSrc && "url(" + resizedSrc + ")";

      return (
        <div
          ref={this.containerRef}
          className={"c-responsive-image " + (this.props.className || "")}
          style={{
            backgroundImage,
            backgroundPosition: this.props.backgroundPosition,
            backgroundSize: "cover",
          }}
        >
          {this.props.children}
        </div>
      );
    } else {
      return (
        <div ref={this.containerRef} className="c-responsive-image">
          {resizedSrc && <img src={resizedSrc} alt="" />}
        </div>
      );
    }
  }

  componentDidMount() {
    this.checkContainerWidth();
  }

  checkContainerWidth() {
    if (this.containerRef && this.containerRef.current) {
      const containerWidth =
        this.containerRef.current.clientWidth * (window.devicePixelRatio || 1);

      if (containerWidth != this.state?.lastWidth) {
        this.setState({
          lastWidth: containerWidth,
        });
      }
    }
  }
}

export default ResponsiveImage;
