import { array, object, string } from "yup";
import { TFunction } from "i18n";
import { FieldNames } from "./constants";
import { courseDatesValidationSchema } from "component-library/src/components/Atoms/FormikInputs/validationSchemas";

export function transferProfileValidationSchema(t: TFunction) {
  return object().shape({
    [FieldNames.Training]: string()
      .max(75)
      .required(
        t("common:validation.required", {
          field: t("common:label.yourCourse"),
        })
      ),
    ...courseDatesValidationSchema(FieldNames.CourseStartDate),
    ...courseDatesValidationSchema(FieldNames.CourseEndDate),
    [FieldNames.Agents]: array().of(string()),
  });
}
