import React from "react";
import { toDate, format } from "date-fns-tz";
import { Trans, useTranslation } from "i18n";

import { FEATURE_FLAGS, useLaunchDarklyFlags } from "shared-services";
import { MemberGroups } from "shared-types";
import { authorizationService } from "shared-auth";
import { IconTextLink } from "component-library";
import { PlanCodePeriod } from "component-library/src/components/Organisms/Billing/types";
import { AddOnCodes } from "component-library/src/components/Organisms/Billing/constants";
import { ReactComponent as ArrowLeftRightIcon } from "component-library/src/images/svg/icons/arrow-left-right.svg";
import { ReactComponent as CrossIcon } from "component-library/src/images/svg/icons/cross-thin.svg";
import { ReactComponent as WarningIcon } from "component-library/src/images/svg/icons/warning-icon.svg";
import { ReactComponent as RedoIcon } from "component-library/src/images/svg/icons/retry-redo.svg";

import {
  AddOnCalculatedStatus,
  addOnNameMap,
  DATE_FORMAT,
  planNameMap,
  SubscriptionCalculatedStatus,
  SubscriptionState,
} from "app/constants";
import LabelValueRow from "components/LabelValueRow/LabelValueRow";
import SectionBox from "components/SectionBox";
import StatusPill from "components/StatusPill/StatusPill";
import AddOnSection from "components/AddOnSection";
import BannerMessage from "components/BannerMessage";
import { IBillingApiResponse } from "services/api/BillingApi/BillingApi";

interface ISubscriptionDataProps {
  handleChangePlan: () => void;
  handleCancelPlan: () => void;
  handleReinstatePlan: () => void;
  subscription?: IBillingApiResponse["subscription"];
  addOns?: IBillingApiResponse["addOns"];
  onBuyAddOnClick: (code: AddOnCodes) => void;
  onCancelAddOnClick: () => void;
  formattedDate: string;
}

const SubscriptionData = ({
  subscription,
  addOns,
  onBuyAddOnClick,
  onCancelAddOnClick,
  handleChangePlan,
  handleCancelPlan,
  handleReinstatePlan,
  formattedDate,
}: ISubscriptionDataProps) => {
  const { t } = useTranslation();
  const isSelfServeCancellationFlagEnabled = useLaunchDarklyFlags(
    FEATURE_FLAGS.SelfServeCancellation
  );

  const isMainPerformer =
    authorizationService.getUserGroup() === MemberGroups.performer;
  const hasAddons = !!(addOns && addOns?.length > 0);
  const isExpired =
    subscription?.calculatedStatus === SubscriptionCalculatedStatus.Expired;
  const isAllowedToSeeAdditionalServices =
    isMainPerformer && hasAddons && !isExpired;
  const isSubscriptionActive = subscription?.state === SubscriptionState.Active;
  const isSubscriptionCanceled =
    subscription?.state === SubscriptionState.Canceled;
  const planName =
    planNameMap[subscription?.planCode as keyof typeof planNameMap];

  const getAddOnNames = (addOns: AddOnCodes[]) => {
    return addOns.map((addOnCode) => t(addOnNameMap[addOnCode])).join(", ");
  };

  const getPlanAndAddOnsString = (planName: string, addOns: AddOnCodes[]) => {
    const addOnNames = getAddOnNames(addOns);

    if (!addOnNames.length) return planName;

    return `${planName}, ${addOnNames}`;
  };

  const getMainSubscriptionCard = () => {
    const currentTerm = subscription?.currentTermStartedAt
      ? `${format(toDate(subscription?.currentTermStartedAt), DATE_FORMAT)} - ${
          subscription?.currentTermEndsAt &&
          format(toDate(subscription?.currentTermEndsAt), DATE_FORMAT)
        }`
      : "-";
    const expiresAt = subscription?.expiresAt
      ? format(toDate(subscription?.expiresAt), DATE_FORMAT)
      : "-";

    return (
      <LabelValueRow
        label={t("performer:accountSettings.mySubscriptionPage.type.label")}
        value={
          <SectionBox
            title={subscription?.planCode ? t(planName) : "-"}
            description={
              isExpired
                ? t("performer:accountSettings.mySubscriptionPage.expired", {
                    date: expiresAt,
                  })
                : t(
                    "performer:accountSettings.mySubscriptionPage.currentTerm",
                    { currentTerm }
                  )
            }
            bgColour="bg-color-neutral-four"
            pill={
              <StatusPill
                isActive={isSubscriptionActive}
                isRenewing={
                  subscription?.calculatedStatus ===
                  SubscriptionCalculatedStatus.Renewing
                }
                isCanceled={isSubscriptionCanceled}
              />
            }
            primaryButton={
              isSelfServeCancellationFlagEnabled &&
              isSubscriptionActive && (
                <IconTextLink
                  text={t("common:button.label.changePlan")}
                  onClick={handleChangePlan}
                  variant="button-icon-text"
                  svgIconStart={<ArrowLeftRightIcon />}
                  className="text-color-brand-two"
                />
              )
            }
            secondaryButton={
              isSelfServeCancellationFlagEnabled &&
              !isExpired && (
                <IconTextLink
                  text={t("common:button.label.cancelSubscription")}
                  onClick={handleCancelPlan}
                  variant="button-icon-text"
                  svgIconStart={<CrossIcon />}
                  className="text-red-cardinal"
                />
              )
            }
          />
        }
      />
    );
  };

  const getAddOnsCards = () => {
    let value = null;

    if (!isAllowedToSeeAdditionalServices || addOns?.length === 0) {
      value = (
        <BannerMessage
          title={t("common:accountSettings.ecpAddon.banner.title")}
          description={
            <Trans
              i18nKey="common:accountSettings.ecpAddon.banner.description"
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content -- injected by i18n
                  <a
                    href="https://support.spotlight.com/hc/en-us"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
              }}
            />
          }
        />
      );
    } else {
      value = addOns?.map((addOn) => (
        <div key={addOn.code}>
          <AddOnSection
            addOn={addOn}
            isSubscriptionActive={isSubscriptionActive}
            onCancelAddOnClick={onCancelAddOnClick}
            onBuyAddOnClick={onBuyAddOnClick}
          />
          {addOn.cancelled && (
            <BannerMessage
              title={t(
                "performer:accountSettings.addOns.cancelledBannerMessage.title"
              )}
              description={t(
                "performer:accountSettings.addOns.cancelledBannerMessage.description"
              )}
            />
          )}
        </div>
      ));
    }

    return (
      <LabelValueRow
        label={t(
          "performer:accountSettings.mySubscriptionPage.additionalServices.label"
        )}
        value={value}
      />
    );
  };

  const getExpiry = () => {
    const period = subscription?.planCode.includes(PlanCodePeriod.Annual)
      ? "1 year"
      : "1 month";

    const planAndAddOnsString = getPlanAndAddOnsString(
      t(planName),
      addOns
        ?.filter(
          (addOn) => addOn.calculatedStatus === AddOnCalculatedStatus.Active
        )
        .map((addOn) => addOn.code) || []
    );

    const paymentString = formattedDate
      ? t("performer:accountSettings.mySubscriptionPage.nextPayment", {
          date: formattedDate,
          amount: "",
          period,
          plan: planAndAddOnsString,
        })
      : "-";

    const value = isSubscriptionCanceled ? (
      <SectionBox
        title={t("performer:accountSettings.mySubscriptionPage.canceled.title")}
        description={t(
          "performer:accountSettings.mySubscriptionPage.canceled.description",
          { date: formattedDate }
        )}
        bgColour="bg-red-pale"
        icon={<WarningIcon width={25} height={25} />}
        primaryButton={
          isSelfServeCancellationFlagEnabled && (
            <IconTextLink
              text={t(
                "performer:accountSettings.mySubscriptionPage.canceled.reinstateButton"
              )}
              onClick={handleReinstatePlan}
              variant="button-icon-text"
              svgIconStart={<RedoIcon />}
              className="text-color-neutral-one"
            />
          )
        }
      />
    ) : (
      paymentString
    );

    if (isExpired) {
      return null;
    }

    return (
      <LabelValueRow
        label={t(
          "performer:accountSettings.mySubscriptionPage.renewsExpires.label"
        )}
        value={value}
      />
    );
  };

  return (
    <div className="flex flex-col gap-6">
      {getMainSubscriptionCard()}
      {getAddOnsCards()}
      {getExpiry()}
    </div>
  );
};

export default SubscriptionData;
